import { useEffect, useState } from "react";

const useIsSticky = (elementId: string, threshold: number = 16): boolean => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const target = document.querySelector(`#${elementId}`) as HTMLElement | null;

    if (!target) {
      console.error("Sticky element not found");
      return;
    }

    const handleScroll = () => {
      const rect = target.getBoundingClientRect();
      // Check if the sticky element is at or past its sticky point (e.g., top <= threshold)
      // console.log(rect.top, threshold)
      const stickyNow = rect.top <= threshold;

      // Update state only if the sticky status has changed
      if (stickyNow !== isSticky) {
        setIsSticky(stickyNow);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky, threshold, elementId]); // Add `isSticky` and `threshold` as dependencies

  return isSticky;
};

export default useIsSticky;