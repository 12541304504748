import styled from "@emotion/styled";

const Label = styled.label`
  & {
    display: flex;
    align-items: center;
  }

  input[type="checkbox"] {
    border-radius: 4px;
    border: solid 2px #707070; /* Increased border thickness */
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    min-width: 1.15rem;
    min-height: 1.15rem;
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    transform: scale(0);
    border-radius: 3px;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #62bbc1;
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
`;
interface ControlledCheckBoxProps {
  id?: string;
  name: string;
  className?: string;
  label?: string;
  checked: boolean;
  onChange: () => void;
}

const ControlledCheckBox = ({
  id,
  name,
  label,
  className,
  checked,
  onChange,
  ...inputProps
}: ControlledCheckBoxProps) => {
  return (
    <Label htmlFor={name} className={className}>
      <input
        id={id ?? ""}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        {...inputProps}
      />
      {label && <span>{label}</span>}
    </Label>
  );
};

export default ControlledCheckBox;
