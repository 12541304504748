import { Flex } from "antd";
import MagicButton from "components/buttons/magicButton";
import { BadgePlus, ClipboardPlusIcon, ListChecks, ListRestart } from "lucide-react";
import usePriceCheckStore from "stores/priceCheckStore";
import useWorkTaskStore from "stores/workTaskStore";

const PriceCheckToolBarButtons = () => {
  const { setSelectedPriceHitListIsOpen, setActiveModal, setCurrentQuery, selectedPriceHits } =usePriceCheckStore();
  const { setCreateTaskIsOpen } = useWorkTaskStore();
  return (
    <Flex
      gap={15}
      justify="center"
      style={{ position: "relative", alignItems: "end" }}
    >
      <MagicButton
        text="Skapa lista"
        type="default"
        tooltipTitle="Skapa lista"
        onClick={() => setCreateTaskIsOpen(true)}
        icon={<ClipboardPlusIcon size={"1em"} strokeWidth={"3px"} />}
      ></MagicButton>

      <MagicButton
        text="Ladda om & rensa"
        type="default"
        tooltipTitle="Ladda om & rensa"
        onClick={() => setCurrentQuery([])}
        icon={<ListRestart size={"1em"} strokeWidth={"3px"} />}
      ></MagicButton>

      <MagicButton
        text="Skapa nya prisjämförelser"
        type="default"
        tooltipTitle="Skapa nya prisjämförelser"
        onClick={() => setActiveModal("create-new-price-comparisons")}
        icon={<BadgePlus size={"1em"} strokeWidth={"3px"} />}
      ></MagicButton>

      <MagicButton
        text="Prisjämförelser"
        count={Object.keys(selectedPriceHits).length}
        type="primary"
        tooltipTitle="Prisjämförelser"
        onClick={() => setSelectedPriceHitListIsOpen(true)}
        icon={<ListChecks size={"1em"} strokeWidth={"3px"} />}
      ></MagicButton>
    </Flex>
  );
};

export default PriceCheckToolBarButtons;
