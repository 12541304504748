import { Button, Tooltip } from "antd";

interface MagicButtonProps {
  text?: string;
  type: "default" | "primary";
  onClick: () => void;
  icon?: React.ReactNode;
  tooltipTitle?: string;
  tooltipColor?: string;
  count?: number; // {{ edit_1 }} Added count prop
  countColor?: string; // {{ edit_2 }} Added countColor prop
  disabled?: boolean;
}

const MagicButton: React.FC<MagicButtonProps> = ({ text, type, onClick, icon, tooltipTitle, tooltipColor, count, countColor, disabled }) => {
  return (
    <>
    {tooltipTitle ? (
      <Tooltip color={tooltipColor ? tooltipColor : "gray"} mouseEnterDelay={0.5} placement="topRight" title={tooltipTitle}>
        <Button
          style={{
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            padding: "1.1rem",
            fontSize: "1rem",
            fontFamily: "HK Grotesk SemiBold",
            borderRadius: "10px",
            width: 'auto',
            position: 'relative'
          }}
          type={type}
          icon={icon}
          onClick={onClick}
          disabled={disabled}
        >
          {text ? <span style={{ marginTop: "2px" }}>{text}</span> : null}
          {count !== undefined && count > 0 && (
            <span style={{
              position: 'absolute',
              top: '1px',
              right: '2px',
              backgroundColor: countColor ? countColor : 'white',
              color: 'black',
              borderRadius: '50%',
              padding: '2px 5px',
              fontSize: '0.75rem',
              lineHeight: '1'
            }}>
              {count}
            </span>
          )}
        </Button>
      </Tooltip>
    ) : (
      <Button
        style={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          padding: "1.1rem",
          fontSize: "1rem",
          fontFamily: "HK Grotesk SemiBold",
          borderRadius: "10px",
          width: 'auto',
          position: 'relative'
        }}
        type={type}
        icon={icon}
        onClick={onClick}
        disabled={disabled}
      >
        {text ? <span style={{ marginTop: "2px" }}>{text}</span> : null}
        {count !== undefined && count > 0 && ( // {{ edit_7 }} Conditionally render count
          <span style={{
            position: 'absolute',
            top: '1px',
            right: '2px',
            backgroundColor: countColor ? countColor : 'white', // {{ edit_8 }} Use countColor or default to white
            color: 'black',
            borderRadius: '50%',
            padding: '2px 5px',
            fontSize: '0.75rem',
            lineHeight: '1'
          }}>
            {count}
          </span>
        )}
      </Button>
    )}
    </> 
  );
};

export default MagicButton;
