import { useEffect, useState } from "react";
import MagicSelect from "./magicSelect";
import { IUser } from "interfaces";
import { USERS_RES_ID } from "utility/constants";
import { useDataProvider } from "@refinedev/core";

const UserSelect = ({
  currentAssignedId,
  onUserAssignChange,
  placeholder,
}: {
  currentAssignedId?: string;
  onUserAssignChange?: (value: string | undefined) => void;
  placeholder?: string;
}) => {
  const dataProvider = useDataProvider();
  const [assignedUser, setAssignedUser] = useState<string | undefined>(currentAssignedId);

  useEffect(() => {
    setAssignedUser(currentAssignedId);
  }, [currentAssignedId]);

  const getUsers = async () => {
    const dp = dataProvider();
    return dp.getList<IUser>({
      resource: USERS_RES_ID,
      pagination: { current: 1, pageSize: 50 },
    });
  };

  const fetchMissingUserOption = async (userId: string) => {
    if (!userId) return [];
    const dp = dataProvider();
    const { data } = await dp.getOne<IUser>({
      resource: USERS_RES_ID,
      id: userId,
    });
    return data ? [data] : [];
  };

  const handleSelect = (value?: string) => {
    setAssignedUser(value);
    onUserAssignChange?.(value);
  };

  return (
    <MagicSelect<IUser, string>
      value={assignedUser}
      formatOption={(option) => ({
        value: option.id,
        label: option.username || option.email || "Unknown Username",
      })}
      fetchMissingOptions={fetchMissingUserOption}
      placeholder={placeholder ?? ""}
      onSelect={handleSelect}
      fetchOptions={() => getUsers().then(response => response?.data) ?? []}
    />
  );
};

export default UserSelect;
