import { Button, Checkbox, Input, Select, Tag } from "antd";
import { EntityMetaData, EntityType, FieldMetaData } from "interfaces/apiTypes.js";
import { FilterItem, FilterOperatorEnum } from "interfaces/baseTypes";
import { EditIcon, X } from "lucide-react";
import React, { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";

const operatorMapping: { [key: number]: FilterOperatorEnum[] } = {
  2: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.CONTAINS, FilterOperatorEnum.STARTS_WITH, FilterOperatorEnum.ENDS_WITH],
  3: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL],
  4: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL],
  5: [],
  6: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS],
  7: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL],
  8: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL],
  9: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.CONTAINS, FilterOperatorEnum.STARTS_WITH, FilterOperatorEnum.ENDS_WITH],
  10: [FilterOperatorEnum.EQUALS, FilterOperatorEnum.NOT_EQUALS, FilterOperatorEnum.LESS_THAN, FilterOperatorEnum.GREATER_THAN, FilterOperatorEnum.LESS_THAN_OR_EQUAL, FilterOperatorEnum.GREATER_THAN_OR_EQUAL],
};

export const FilterBuilder = ({
  metdataFunction,
  onFiltersChange,
  initialFilterItems = [],
}: {
  metdataFunction: () => Promise<EntityMetaData | undefined>;
  onFiltersChange: (filters: FilterItem[]) => void;
  initialFilterItems?: FilterItem[];
}) => {
  const [filterItems, setFilterItems] = useState<FilterItem[]>([]); // State to hold the list of FilterItems
  const [selectedField, setSelectedField] = useState<FieldMetaData | undefined>(); // State for selected field
  const [selectedOperator, setSelectedOperator] = useState<FilterOperatorEnum>(); // State for selected operator
  const [value, setValue] = useState("");
  const [activeMetadata, setActiveMetadata] = useState<EntityMetaData>();
  const [fieldKeys, setFieldKeys] = useState<string[]>([]);
  const { t } = useTranslation();
  const [fieldsDict, setFieldsDict] = useState<{ [key: string]: any }>({}); // New state for fields dictionary
  const [isOperatorVisible, setIsOperatorVisible] = useState(true); // New state for operator visibility
  const [editingIndex, setEditingIndex] = useState<number | undefined>();

  useEffect(() => {
    setFilterItems(initialFilterItems);
  }, [initialFilterItems]);

  const setAllFieldKeys = (metadata: EntityMetaData | undefined) => {
    if (!metadata || fieldKeys.length > 0) {
      return;
    }

    const fieldKeysTemp: string[] = [];
    const mainEntity = metadata.metaData?.get?.find(
      (entity) =>
        entity.propertyPaths?.length === 1 && entity.propertyPaths[0] === ""
    );

    const findFields = (entities: EntityType[], parentField: string = "") => {
      entities.forEach((entity) => {
        entity.fields?.forEach((field) => {
          if (!field.isFilterable || fieldKeys.includes(field?.name ?? "")) {
            return;
          }
          const fullFieldName = parentField
            ? `${parentField}.${field.name}`
            : field.name;

          // Add the field to the fieldsDict
          setFieldsDict(prev => ({ ...prev, [fullFieldName ?? ""]: field })); // Store field in fieldsDict

          if (field.isEntity) {
            const nextEntity = metadata.metaData?.get?.find((e) =>
              e.propertyPaths?.includes(fullFieldName ?? "")
            );
            if (nextEntity) {
              findFields([nextEntity], fullFieldName ?? ""); // Pass the full field name as the parent
            }
          } else {
            fieldKeysTemp.push(fullFieldName ?? "");
          }
        });
      });
    };

    if (mainEntity) {
      findFields([mainEntity]);
    }
    fieldKeysTemp.sort();
    setFieldKeys(fieldKeysTemp);
  };

  useEffect(() => {
    setAllFieldKeys(activeMetadata);
  }, [activeMetadata]);

  useEffect(() => {
    if (!activeMetadata) {
      metdataFunction().then((metadata) => {
        setActiveMetadata(metadata);
        setAllFieldKeys(metadata);
      });
    }
  }, [metdataFunction, activeMetadata]);

  const handleAddOrUpdateFilter = (index?: number) => {
    if (selectedField && selectedOperator && value) {
      const newFilterItem = {
        fieldName: selectedField.name ?? "",
        operator: selectedOperator,
        value,
      };

      let updatedFilterItems;
      if (index !== undefined) {
        // Update existing filter item
        updatedFilterItems = filterItems.map((item, i) => (i === index ? newFilterItem : item));
      } else {
        // Add new filter item
        updatedFilterItems = [...filterItems, newFilterItem];
      }

      setFilterItems(updatedFilterItems);
      onFiltersChange(updatedFilterItems);

      // Reset input fields after adding/updating
      setSelectedField(undefined);
      setSelectedOperator(undefined);
      setValue("");
      setIsOperatorVisible(true);
    }
  };

  const handleRemoveFilter = (index: number) => {
    const updatedFilterItems = filterItems.filter((_, i) => i !== index);
    setFilterItems(updatedFilterItems);
    // Call the callback with the updated filters
    onFiltersChange(updatedFilterItems);
  };

  const handleFieldChange = (fieldKey: string) => {
    const selectedField = fieldsDict[fieldKey];
    selectedField.name = fieldKey;
    const filteredOperators = operatorMapping[selectedField?.type] || [];
    if(filteredOperators.length == 0 && selectedField.fieldType == 5){
        setIsOperatorVisible(false);
        setSelectedOperator(FilterOperatorEnum.EQUALS);
        setValue("false");
    }else{
        setIsOperatorVisible(true);
        setValue("");
        setSelectedField(selectedField);
        setSelectedOperator(filteredOperators[0]);
    }
  };

  const handleEditFilter = (index: number) => {
    const itemToEdit = filterItems[index];
    setSelectedField(fieldsDict[itemToEdit.fieldName]);
    setSelectedOperator(itemToEdit.operator);
    setValue(itemToEdit.value);
    // Store the index of the item being edited
    setEditingIndex(index);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "20px",
      }}
    >
      {/* Input fields for filter item */}
      {/* <pre>{JSON.stringify(activeMetadata, null, 2)}</pre> */}
      <Select
        value={selectedField?.name ?? ""}
        allowClear
        placeholder="Välj fält"
        onChange={handleFieldChange}
      >
        <option value="">Välj fält</option>
        {fieldKeys
          .map((fieldKey) => ({ fieldKey, translated: t(fieldsDict[fieldKey]?.languageKey ?? fieldKey) }))
          .sort((a, b) => a.translated.localeCompare(b.translated))
          .map(({ fieldKey, translated }) => (
            <option key={fieldKey} value={fieldKey}>
              {translated}
            </option>
          ))}
      </Select>
      {isOperatorVisible && (
        <Select
          value={selectedOperator}
          allowClear
          placeholder="Välj operator"
          onChange={(enumVale) => setSelectedOperator(enumVale)}
        >
          {Object.values(FilterOperatorEnum)
            .filter(operator => operatorMapping[selectedField?.fieldType ?? 2]?.includes(operator)) // Filter operators based on field type
            .map((operator) => (
              <option key={operator} value={operator}>
                {t(operator)}
              </option>
            ))}
        </Select>
      )}

      {(() => {
        switch (selectedField?.fieldType) {
          case 5:
            return (
              <div>
                <Checkbox onChange={(e) => setValue(e.target.checked.toString())}/>
              </div>
            );
          // Add more cases for other field types if needed
          default:
            return (
            <Input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter value"
              />
            );
        }
      })()}

      <Button type="primary" onClick={() => handleAddOrUpdateFilter(editingIndex)} disabled={!selectedField || !selectedOperator || !value}>
        {editingIndex !== undefined ? "Spara" : "Lägg till"} {/* Change button text based on edit mode */}
      </Button>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {filterItems.map((item, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px', border: '1px solid #e0e0e0', borderRadius: '4px' }}>
            <span style={{ marginRight: '10px' }}>{t(fieldsDict[item.fieldName]?.languageKey ?? item.fieldName)}</span>
            <Tag>{t(item.operator)}</Tag>
            <span style={{fontWeight: 'bold' }}>{item.value}</span> {/* Made the value bold */}
            <Button onClick={() => handleRemoveFilter(index)} style={{ marginLeft: 'auto' }}>
              <X size={16} />
            </Button>
            <Button onClick={() => handleEditFilter(index)} style={{ marginLeft: '10px' }}>
              <EditIcon size={16} />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
