import { useEffect, useRef, useState } from 'react';
import { useOpenApiClient } from './base/useOpenApiClient';
import { ErrorResponse, GetManyRequestOptions } from 'interfaces/baseTypes';
import { EntityMetaData, PriceTracker } from 'interfaces/apiTypes';
export interface PriceTrackerOne {
    metaData: EntityMetaData;
    value: PriceTracker;
}

export interface PriceTrackerMany {
    metaData: EntityMetaData;
    items: PriceTracker[];
    totalCount: number;
    hasNextPage: boolean;
}

const usePriceTrackerOpenApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ErrorResponse | null>(null);
    const {client, getClient} = useOpenApiClient();
    const clientRef = useRef(client);

    useEffect(() => {
        clientRef.current = client;
    }, [client]);
    
    async function getPriceTrackerById(id: number): Promise<PriceTrackerOne | undefined>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.GET('/api/pricetracker/{id}', {
                params: { path: { id } }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }

            return response?.data as PriceTrackerOne;

        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function getPriceTrackers(options: GetManyRequestOptions = {}): Promise<PriceTrackerMany | undefined>
    {
        const { page = 0, pageSize = 10, order, orderByField, filters } = options;
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.GET('/api/pricetracker', {
                params: { query: { page: page, pageSize: pageSize, order: order,  orderByField: orderByField, filters: filters } }
            });
            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }

            return response?.data as PriceTrackerMany;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function priceTrackerBatchImport<T>(articleNumbers: string[], searchKeys: string[], suppliers: string[], supplierNumbers: string[], workTaskId: number, languageId: number): Promise<void>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            await clientRef.current?.POST('/api/pricetracker/batch-import', {
                body: {articleNumbers: articleNumbers, searchKeys: searchKeys, suppliers: suppliers, supplierNos: supplierNumbers, workTaskId: workTaskId, languageId: languageId},
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function priceTrackerBatchSynchronize<T>(ids: number[]): Promise<void>{
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            await clientRef.current?.PUT('/api/pricetracker/batch-synchronize', {
                params:{
                    query: {
                        priceTrackerIds: ids
                    }
                }
            });

        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function getMetadata(): Promise<EntityMetaData | undefined>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.GET('/api/pricetracker/metadata');

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
            return response?.data as EntityMetaData;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    return { getPriceTrackers, getPriceTrackerById, priceTrackerBatchImport, priceTrackerBatchSynchronize, getMetadata, loading, errorMessage };
}

export default usePriceTrackerOpenApi;
