export const USERS_RES_ID = "users";
export const TENANT_RES_ID = "tenants";
export const ROLE_RES_ID = "roles";
export const PRODUCT_ITEM_RES_ID = "productItems";
export const LOCALE_RES_ID = "locale";
export const SETTINGS_RES_ID = "settings";
export const JOB_RES_ID = "jobs";
export const FILE_RES_ID = "files";
export const RELATION_RES_ID = "relations";
export const RESOURCES_RES_ID = "resources";
export const PRODUCT_RES_ID = "products";
export const CATEGORY_RES_ID = "categories";
export const SPECIFICATION_LABEL_RES_ID ="specificationLabels" 
export const SPECIFICATION_RES_ID = "specifications"
export const VIDEO_RES_ID = "videos"
export const JOB_TEXT_REWRITE= "AiTextRewrite";
export const UNIT_RES_ID = "units";