import { Avatar, Tooltip } from "antd";
import initials from "initials";

const InitialsWithTooltip: React.FC<{ name?: string; textColor?: string; backgroundColor?: string }> = ({
  name,
  textColor = "white",
  backgroundColor = "#195178",
}) => {
  const tooltipContent = name || "N/A";
  
  const initialsToDisplay = initials(name ?? "") || "-";

  return (
    <Tooltip title={`${tooltipContent}`}>
      <Avatar
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: "21px",
          height: "21px",
          fontFamily: "HK Grotesk Bold",
        }}
      >
        {initialsToDisplay.toUpperCase()}
      </Avatar>
    </Tooltip>
  );
};

export default InitialsWithTooltip;
