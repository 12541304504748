import React from "react";
import { Button, Popconfirm, Tooltip } from "antd";
import styled from "@emotion/styled";
import { ButtonProps } from "antd/lib/button";
import { ListChecks } from "lucide-react";
import usePriceCheckStore from "stores/priceCheckStore";

const StickyButtonsWrapper = styled.div<{ hideBtns: boolean }>`
  position: sticky;
  visibility: ${({ hideBtns }) => (hideBtns ? "hidden" : "visible")};
  top: 16px;
  height: 0px;
  width: 0px;
  float: right;
  z-index: 10;
  & .sticky-content {
    position: absolute;
    top: 0;
    right: 0;
  }
  & .save-icons {
    display: flex;
    gap: 0.7rem;
  }
  .save-text-position {
    position: absolute;
    right: 0;
    width: 5px;
    top: 9px;
  }
  .save-text-vertical {
    transform: rotate(90deg);
    font-size: 0.7rem;
    display: inline-block;
  }
`;

interface StickySaveButtonsProps {
  formLoading: boolean;
  isInitalLoading: boolean;
  // saveButtonProps: ButtonProps & {
  //   onClick: () => void;
  // };
  hideBtns?: boolean;
  compId?: string;
}

const PriceCheckStickyButtons: React.FC<StickySaveButtonsProps> = ({
  formLoading,
  isInitalLoading,
  // saveButtonProps,
  hideBtns = false,
  compId,
}) => {
  const { setSelectedPriceHitListIsOpen: setSelectedListIsOpen } = usePriceCheckStore();
  return (
    <StickyButtonsWrapper
      id={`${compId ?? "sticky-buttons-wrapper"}`}
      hideBtns={hideBtns}
    >
      <div className="sticky-content">
        <div className="save-icons">
          <Tooltip
            color={false ? "red" : "gray"}
            placement="topRight"
            title={"Produktlista"}
          >
            <Button
              title="Spara"
              type="primary"
              shape="circle"
              size="large"
              // {...saveButtonProps}
              icon={<ListChecks />}
              loading={formLoading && !isInitalLoading}
              disabled={formLoading && !isInitalLoading}
              onClick={() => {setSelectedListIsOpen(true)}}
            />
          </Tooltip>
        </div>
      </div>
    </StickyButtonsWrapper>
  );
};

export default PriceCheckStickyButtons;