import { Button, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import {
  CircleCheck,
  ClipboardPlusIcon,
  List,
  Pencil,
  SquarePlus,
} from "lucide-react";
import {
  ColumnConfig,
  DEFAULT_LIST_COL_HEIGHT_PX,
  SimpleListView,
} from "components/listViews/simpleListView";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { WorkTask, WorkTaskUpdateDto } from "interfaces/apiTypes";
import { BaseItem, FilterOperatorEnum } from "interfaces/baseTypes";
import useWorkTaskStore from "stores/workTaskStore";
import WorkTaskSectionHeader from "./header/sectionHeader";
import { defaultSectionHeaderIconStyle } from "../styles/workTaskStyles";
import InitialsWithTooltip from "components/common/icons/initialsWithTooltip";
import useWorkTaskStatus from "components/hooks/workTask/useWorkTaskStatus";

const columns: ColumnConfig<BaseItem<WorkTask>>[] = [
  {
    header: "Namn",
    accessorKey: "name",
    cell: ({ row }) => <>{row.original.item.name}</>,
    size: 100,
    sort: true,
  },
  {
    header: "Typ",
    accessorKey: "type",
    cell: ({ row }) => (
      <Tag color={row.original.item.type === 0 ? "cyan" : "orange"}>
        {row.original.item.type === 0 ? "Produkt" : "Prisjämförelse"}
      </Tag>
    ),
    size: 50,
    sort: true,
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: ({ row }) => (
      <Tag
        color={
          row.original.item.status === 0
            ? "blue"
            : row.original.item.status === 1
            ? "orange"
            : "green"
        }
      >
        {row.original.item.status === 0
          ? "Inte påbörjad"
          : row.original.item.status === 1
          ? "Pågående"
          : "Avslutad"}
      </Tag>
    ),
    size: 50,
    sort: true,
  },
  {
    header: "Skapad av",
    accessorKey: "createdBy",
    cell: ({ row }) => (
      <InitialsWithTooltip name={row.original.item.createdBy?.email ?? ""} />
    ),
    size: 50,
    sort: true,
  },
  {
    header: "Tilldelad",
    accessorKey: "assignedTo",
    cell: ({ row }) => (
      <InitialsWithTooltip
        name={row.original.item.assignedTo?.email ?? ""}
        backgroundColor={
          !row.original.item.assignedTo?.email ? "#E1E8ED" : "#52c41a"
        }
        textColor={
          !row.original.item.assignedTo?.email ? "rgba(0, 0, 0, 0.88)" : "white"
        }
      />
    ),
    size: 50,
    sort: true,
  },
  {
    header: "Beskrivning",
    accessorKey: "description",
    cell: ({ row }) => <>{row.original.item.description}</>,
    size: 300,
    visible: false,
  },
];

const WorkTaskLists: React.FC<{ sectionHeaderTitle?: string }> = ({
  sectionHeaderTitle,
}) => {
  const { getWorkTasks } = useWorkTaskOpenApi();
  const {
    setCreateTaskIsOpen,
    setUpdateTaskIsOpen,
    setSelectedWorkTask,
    selectedWorkTask,
  } = useWorkTaskStore();
  const { setWorkTaskStatus } = useWorkTaskStatus();

  return (
    <>
      <div style={{ position: "sticky", top: 0, zIndex: 11 }}>
        <WorkTaskSectionHeader
          title={sectionHeaderTitle ?? ""}
          subText={
            selectedWorkTask?.id
              ? `${selectedWorkTask?.item?.name ?? ""} (${
                  selectedWorkTask?.id
                })`
              : "Ingen lista vald"
          }
          icon={<List style={defaultSectionHeaderIconStyle} />}
          showSeparatorLine={true}
          renderActionElement={() => (
            <>
              <Tooltip title="Markera arbetslista som avslutad">
                <Button
                  disabled={
                    !selectedWorkTask?.id ||
                    selectedWorkTask?.item?.status === 2
                  }
                  type="primary"
                  onClick={() =>
                    setWorkTaskStatus(selectedWorkTask, 2, "workTasks")
                  }
                >
                  <CircleCheck />
                </Button>
              </Tooltip>
              <Tooltip title="Redigera arbetslista">
                <Button
                  disabled={!selectedWorkTask?.id}
                  type="primary"
                  onClick={() => {
                    setUpdateTaskIsOpen(true);
                  }}
                >
                  <Pencil />
                </Button>
              </Tooltip>
              <Tooltip title="Skapa ny arbetslista">
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateTaskIsOpen(true);
                  }}
                >
                  <ClipboardPlusIcon />
                </Button>
              </Tooltip>
            </>
          )}
        />
      </div>

      <SimpleListView<WorkTask>
        queryKey="workTasks"
        filterItems={[
          {
            fieldName: "type",
            operator: FilterOperatorEnum.EQUALS,
            value: "0",
          },
        ]}
        resizableColumns={false}
        selectable={false}
        onRowClick={(item) => {
          setSelectedWorkTask(item);
        }}
        fetchItems={async (
          page,
          pageSize,
          sortOrder,
          orderByField,
          filterString
        ) =>
          await getWorkTasks({
            page: page,
            pageSize: pageSize,
            order: sortOrder,
            orderByField: orderByField,
            filters: filterString,
          })
        }
        columns={columns}
        renderHeader={() => {
          return <React.Fragment></React.Fragment>;
        }}
        listLoadingHeightPx={DEFAULT_LIST_COL_HEIGHT_PX * 5}
      />
    </>
  );
};

export default WorkTaskLists;
