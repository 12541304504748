import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { ILanguage } from 'interfaces';

type Currency = 'SEK' | 'DKK' | 'NOK'

const localeToCurrency: { [key: string]: Currency } = {
  'sv': 'SEK',
  'da': 'DKK',
  'no': 'NOK'
}

interface LocaleState {
  locale: string
  languages: ILanguage[]
  currency: Currency
  activeLanguage: ILanguage | null
  setLanguages: (by: ILanguage[]) => void
  setCurrency: (by: Currency) => void
  getLocales: () => string[]
  setActiveLanguage: (language: ILanguage) => void
  setActiveLanguageByLocale: (locale: string) => void
}

export const useLocaleStore = create<LocaleState>()(
  persist(
    (set, get) => ({
      locale: "sv",
      languages: [],
      activeLanguage: null,
      currency: 'SEK',
      setLanguages: (languages) => set((state) => ({ languages })),
      setCurrency: (newCurrency) => set((state) => ({ currency: newCurrency })),
      getLocales: () => get().languages.map(lang => lang.locale),
      setActiveLanguage: (language) => set((state) => ({ activeLanguage: language, locale: language.locale, currency: localeToCurrency[language.locale] })),
      setActiveLanguageByLocale: (locale) => set((state) => ({ activeLanguage: state.languages.find(lang => lang.locale === locale), locale: locale, currency: localeToCurrency[locale] })),
    }),
    {
      name: "lang-data-storage",
    }
  )
);
