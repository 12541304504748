import type { components } from '../apiClients/base/schema';

type DeepRequired<T> = T extends object ? {
    [K in keyof T]-?: T[K] extends (infer U)[] 
      ? DeepRequired<U>[] 
      : DeepRequired<T[K]>
  } : T;

export type EntityMetaData = components["schemas"]["EntityMetaDataResponse"];
export type EntityType = components["schemas"]["MetaDataType"];
export type FieldMetaData = components["schemas"]["FieldMetaData"];
export type EnumInfo = components["schemas"]["EnumMetaData"] & {
    translationKey?: string;
    enumDisplayValue?: string;
};

export type PatchRequest = components["schemas"]["PatchRequest"];
export type PatchRequestItem = components["schemas"]["DtoValuePatch"]

export type PriceTracker = DeepRequired<components["schemas"]["PriceTrackerDtoCsDtoValue"]>;
export type PriceTrackerItem = DeepRequired<components["schemas"]["PriceTrackerItemDto"]>;
export type ProductTranslation = DeepRequired<components["schemas"]["ProductTranslationDto"]>;
export type SalesChannel = DeepRequired<components["schemas"]["SalesChannelDto"]>;

export type WorkTask = DeepRequired<components["schemas"]["WorkTaskDto"]>;
export type WorkTaskType = DeepRequired<components["schemas"]["TaskType"]>;
export type WorkTaskCreateDto = components["schemas"]["WorkTaskCreateDto"];
export type WorkTaskUpdateDto = components["schemas"]["WorkTaskUpdateDto"];

export enum TaskTypeEnum {
    Product = 0,
    PriceTracker = 1,
}

export type Product = DeepRequired<components["schemas"]["ProductDto"]> & {
    getName(): string;
    getDescription(): string;
    getPrice(): number;
    getImageUrl(): string;
    getEan(): string;
    getSalePrice(): number;
    getItemNumber(): string;
    getCompletenessPercentage(): number;
    getItemGroupName(): string;
    getSaleChannels(): SalesChannel[];
};

export function enhanceProduct(product: DeepRequired<components["schemas"]["ProductDto"]>, languageId: number): Product {
    return {
        ...product,
        getName: function() {
            return this.translations?.find(t => t.languageId === languageId)?.name ?? this.translations?.[0]?.name ?? 'Namn Saknas';
        },
        getDescription: function() {
            return this.translations?.find(t => t.languageId === languageId)?.description ?? this.translations?.[0]?.description ?? '';
        },
        getPrice: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.translations?.find(t => t.languageId === languageId)?.salePriceInVat ?? 0;
        },
        getImageUrl: function() {
            return this.mediaAssets?.sort((a, b) => a.order - b.order)[0]?.mediaAsset?.resolutions?.find(r => r.size === 1)?.url ?? this.mediaAssets?.sort((a, b) => a.order - b.order)[0]?.mediaAsset?.url ?? '';
        },
        getEan: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.ean ?? '';
        },
        getSalePrice: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.translations?.find(t => t.languageId === languageId)?.salePriceInVat ?? 0;
        },
        getItemNumber: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.itemNo ?? '';
        },
        getCompletenessPercentage: function() {
            return this.translations?.find(t => t.languageId === languageId)?.completeness.percentage ?? 0;
        },
        getItemGroupName: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.group.name ?? '';
        },
        getSaleChannels: function() {
            return this.salesChannels?.map(sc => sc.salesChannel) ?? [];
        }
    };
}
export interface ResponseWrapperMany<T> {
    metaData: EntityMetaData;
    items: T[];
    totalCount: number;
    hasNextPage: boolean;
}

class ClientNotReadyError extends Error {
    code: string;

    constructor(message: string, code: string) {
        super(message);
        this.code = code;
        this.name = this.constructor.name; // Set the error name to the class name
    }
}

export { ClientNotReadyError };