import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { EnumInfo } from "interfaces/apiTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEnumValuesFromMetadata, translateEnumValues } from "utility/helper";
import MagicSelect from "./magicSelect";

const formatWorkTaskOption = (option: EnumInfo) => ({
  value: option.intValue,
  label: option.enumDisplayValue || "Unknown", // Provide a default value
});

const WorkTaskStatusSelect = ({
  currentStatus,
  onStatusChange,
}: {
  currentStatus?: number;
  onStatusChange?: (value: number | undefined) => void;
}) => {
  const { getMetadata } = useWorkTaskOpenApi();
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<number | undefined>(currentStatus);

  useEffect(() => {
    setSelectedStatus(currentStatus);
}, [currentStatus]);

  const handleSelect = (value: number | undefined) => {
    setSelectedStatus(value);
    onStatusChange?.(value);
  };

  const getStatusOptions = async () => {
    const metadata = await getMetadata();
    const enhancedStatusOptions = metadata
      ? translateEnumValues(
          getEnumValuesFromMetadata(
            metadata,
            "Toolbox.Response.WorkTaskDto.WorkTaskDto",
            "Status"
          ),
          t
        )
      : [];
    return enhancedStatusOptions;
  };

  return (
    <MagicSelect<EnumInfo, number | undefined>
      value={selectedStatus}
      formatOption={formatWorkTaskOption}
      // fetchMissingOptions={fetchMissingOptions}
      placeholder="Välj liststatus"
      onSelect={handleSelect}
      fetchOptions={getStatusOptions}
    />
  );
};

export default WorkTaskStatusSelect;
