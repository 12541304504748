import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { notification } from "antd";
import { FilterItem, FilterOperatorEnum } from "interfaces/baseTypes";
import { EntityMetaData, EnumInfo, PatchRequest, PatchRequestItem } from "interfaces/apiTypes.js";
import { ILanguage } from "interfaces/index";

export enum RelationType {
  Manual = "Manual",
  SafetySheet = "SafetySheet",
  DimensionalSheet = "DimensionalSheet",
  ProductImage = "ProductImage",
  SpareParts = "SpareParts",
  RelatedParts = "RelatedParts",
  Accessories = "Accessories",
}

export const stringToColor = (str: string) => {
  if (str == "Focus") {
    return "#005488";
  }

  if (str == "Tura") {
    return "#000";
  }

  if (str == "pågående") {
    return "#23b06e";
  }

  if (str == "avslutat") {
    return "#3eb2fa";
  }

  if (str == "pausat") {
    return "#f5ad42";
  }

  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

export const hashCode = (value: string) => {
  var hash = 0,
    i,
    chr;
  if (value?.length === 0) return hash;
  for (i = 0; i < value?.length; i++) {
    chr = value?.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export function getUniqueValuesFromArrayInArray<T>(
  dataSource: readonly T[],
  key: keyof T
): string[] {
  const allIds = dataSource?.flatMap((obj) => obj[key] as unknown as string);
  const uniqueIDs = new Set<string>(allIds);
  return Array.from<string>(uniqueIDs);
}

export const decodeTokenAndSave = (response: any) => {
  const token = response?.data?.token;
  const decodedTokenObj = jwtDecode(token);
  localStorage.setItem("user", JSON.stringify({ ...decodedTokenObj, token }));
};

export const getUserInfoFromCache = () => {
  try {
    const data = localStorage.getItem("user");
    const userInfo = data ? JSON.parse(data) : null;
    return userInfo;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getDescriptionFromLocale = (locale: string) => {
  if (locale == "sv") {
    return "Svenska";
  }

  if (locale == "da") {
    return "Danska";
  }

  if (locale == "no") {
    return "Norska";
  }

  return "Svenska";
};

export const formatDate = (rawDate: string) => {
  const date = dayjs(rawDate?.toString());
  return date.format("YYYY-MM-DDTHH:mm:ss");
};

export function splitArrayEvenlyIntoThreeChunks<T>(
  arr: T[] | undefined
): [T[], T[], T[]] {
  // Return empty arrays if input is not an array
  if (!Array.isArray(arr)) {
    return [[], [], []];
  }

  let part1: T[] = [],
    part2: T[] = [],
    part3: T[] = [];
  let totalItems: number = arr.length;
  let partSizeBase: number = Math.floor(totalItems / 3);
  let extraItems: number = totalItems % 3;

  // Determine the sizes of each part dynamically
  let part1Size: number = partSizeBase + (extraItems > 0 ? 1 : 0);
  let part2Size: number = partSizeBase + (extraItems > 1 ? 1 : 0);

  for (let i = 0; i < arr.length; i++) {
    if (i < part1Size) {
      part1.push(arr[i]);
    } else if (i < part1Size + part2Size) {
      part2.push(arr[i]);
    } else {
      part3.push(arr[i]);
    }
  }

  return [part1, part2, part3];
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    function () {
      notification.info({
        message: "Text kopierad",
        duration: 1,
      });
    },
    function (err) {
      console.error("Could not copy text: ", err);
    }
  );
}

export function areArraysEqual<T>(
  a: T[] | null | undefined,
  b: T[] | null | undefined,
  comparator: (x: T, y: T) => boolean = (x, y) => x === y
): boolean {
  // Check if both are null or undefined, consider them equal
  if (a === b) return true;

  // If either is null/undefined and not both, they are not equal
  if (a == null || b == null) return false;

  // Proceed with length and content checks
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (!comparator(a[i], b[i])) return false;
  }
  return true;
}

export const formatISO8601DateString = (
  dateStr: string | null | undefined,
  delimiter: "/" | "-" = "/",
  withTimestamp: boolean = false
): string | null => {
  if (!dateStr) {
    // console.error('Date string is null, undefined or empty.');
    return null;
  }

  try {
    const date = new Date(dateStr);

    if (isNaN(date.getTime())) {
      // console.error('Invalid date string.');
      return null;
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    // Create the date string with the desired delimiter
    let formattedDate = `${day}${delimiter}${month}${delimiter}${year}`;

    if (withTimestamp) {
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      formattedDate = `${formattedDate} ${hours}:${minutes}:${seconds}`;
    }

    return formattedDate;
  } catch (error) {
    // console.error('An error occurred while formatting the date:', error);
    return null;
  }
};

export function calculatePercentageDiff(
  oldPrice: number,
  newPrice: number,
  roundOffDecimals: number = 2
): number | null {
  if (oldPrice === 0) {
    return null;
  }
  const difference = ((newPrice - oldPrice) / oldPrice) * 100;
  return parseFloat(difference.toFixed(roundOffDecimals));
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

// Function to format date with time (handles Date, string, and undefined inputs)
export function formatDateWithTime(dateInput: Date | string | undefined): string {
  if (!dateInput) {
    return "No Date"; // Return fallback value for undefined or null inputs
  }

  // Ensure input is converted to a Date object
  const parsedDate = new Date(dateInput);

  // Check if the date is valid
  if (isNaN(parsedDate.getTime())) {
    return "Invalid Date";
  }

  return parsedDate.toLocaleString("sv-SE", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "Europe/Stockholm",
  });
}

export const addOrUpdateFilterItem = (
  filters: FilterItem[],
  setFilters: React.Dispatch<React.SetStateAction<FilterItem[]>>,
  fieldName: string,
  value: string,
  operator: FilterOperatorEnum
): FilterItem => {
  const newFilterItem: FilterItem = {
    fieldName: fieldName,
    value: value,
    operator: operator
  };

  // Check if the filter already exists
  const index = filters.findFilterIndexByFieldName(fieldName);
  if (index !== -1) {
    // Update existing filter value
    filters[index].value = value;
  } else {
    // Add new filter item
    filters.push(newFilterItem);
  }

  // Update the state with the new filters array
  setFilters([...filters]);
  return newFilterItem;
};

export const removeFilterItem = (filters: FilterItem[], setFilters: React.Dispatch<React.SetStateAction<FilterItem[]>>, fieldName: string): FilterItem[] => {
  const newFilters = filters.filter(filter => filter.fieldName !== fieldName);
  setFilters(newFilters);
  return newFilters;
};

export const convertFiltersToString = (filters: FilterItem[]): string => {
  return filters.map(filter => 
      `${filter.fieldName}::${filter.operator}::${filter.value}`
  ).join('@@');
};

export const convertFiltersToStringIncludeLanguage = (filters: FilterItem[], activeLanguage: ILanguage | null, fieldName: string = "languageId"): string => {
  return filters?.concat(ensureLanguageFilterItem(filters, fieldName, activeLanguage)).map(filter => 
      `${filter.fieldName}::${filter.operator}::${filter.value}`
  ).join('@@');
};

export const convertStringToFilters = (queryString: string): FilterItem[] => {
  if (!queryString) {
    return [];
  }
  return queryString.split('@@').map(item => {
    const [fieldName, operator, value] = item.split('::');
    return { fieldName, operator, value } as FilterItem;
  });
};

export const getEnumValuesFromMetadata = (metadata: EntityMetaData, className: string, fieldName: string): EnumInfo[] => {
  const classMetadata = metadata.metaData?.get?.find(c => c.fullName == className);
  if (!classMetadata) {
    return [];
  }
  const enumInfo = classMetadata.fields?.find(f => f.name == fieldName)?.enumInfo ?? [];
  return enumInfo.map(enumItem => ({
    ...enumItem,
    translationKey: `${className}.${fieldName}.${enumItem.enumValue}`
  }));
};

export const ensureLanguageFilterItem = (filterItems: FilterItem[], fieldName: string , activeLanguage: ILanguage | null ): FilterItem[] => {
  return filterItems.find(filter => filter.fieldName == fieldName) ? filterItems : [...filterItems, {fieldName: fieldName, operator: FilterOperatorEnum.EQUALS, value: activeLanguage?.id ?? 1}];
};

export const translateEnumValues = (enumValues: EnumInfo[], t: (key: string) => string): EnumInfo[] => {
  return enumValues.map(enumItem => ({
    ...enumItem,
    enumDisplayValue: t(enumItem.translationKey ?? "")
  }));
};

export const generatePatchRequest = <T extends object>(object: T): PatchRequest => {
  return {
    values: Object.keys(object).map(key => ({
      propertyPath: key,
      value: object[key as keyof T] ?? null
    }))
  };
};
