import React, { useCallback, useEffect } from "react";
import { Button, DatePicker, Form, Input, Tooltip } from "antd";
import { IUser } from "interfaces";
import { useGetIdentity, HttpError } from "@refinedev/core";
import { useForm } from "antd/lib/form/Form";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { useNotification } from "@refinedev/core";
import { WorkTaskCreateDto } from "interfaces/apiTypes";
import UserSelect from "components/common/select/userSelect";
import { FormItemProps } from "antd/lib";
import TypeSelect from "components/common/select/typeSelect";
import MagicButton from "components/buttons/magicButton";

// Create a strictly typed version of Form.Item
function TypedFormItem<K extends keyof WorkTaskCreateDto>(
  props: FormItemProps<WorkTaskCreateDto[K]> & { name: K }
) {
  return <Form.Item {...props} />;
}

interface AddWorkTaskListProps {
  onSubmitSuccess?: () => void;
  onSubmitFailure?: (error: any) => void;
}

const AddWorkTaskList: React.FC<AddWorkTaskListProps> = ({
  onSubmitSuccess,
  onSubmitFailure,
}) => {
  const { open } = useNotification();
  const [form] = useForm<WorkTaskCreateDto>();
  const { data: identity } = useGetIdentity<IUser>();
  const { createWorkTask, errorMessage, loading } = useWorkTaskOpenApi();
  const [formIsValid, setFormIsValid] = React.useState<boolean>(false);
  const watchedFormValues = Form.useWatch([], form);

  const handleSubmit = async (values: WorkTaskCreateDto) => {
    await createWorkTask(values);
    if (errorMessage) {
      onSubmitFailure?.(errorMessage);
      open?.({
        message: "Misslyckades med att skapa arbetslista.",
        type: "error",
      });
    }

    if (!loading && !errorMessage) {
      open?.({
        message: "Arbetslista skapad.",
        type: "success",
      });
      onSubmitSuccess?.();
    }
  };

  useEffect(() => {
    // Validate form fields to check if they are valid
    form
      .validateFields({ validateOnly: true })
      .then(() => setFormIsValid(true))
      .catch(() => setFormIsValid(false));
  }, [form, watchedFormValues]);

  useEffect(() => {
    // Secure that current userId is set
    form.setFieldsValue({ createdById: identity?.id });
  }, [identity, form]);

  const handleAssignUserSelect = useCallback(
    (userId: string | undefined) => {
      form?.setFieldsValue({ assignedToId: userId });
    },
    [form]
  );

  const handleSelectProductType = useCallback(
    (typeInt: number | undefined | null) => {
      if (typeInt === null || typeInt === undefined) {
        form?.setFieldsValue({ type: undefined });
      }
      if (typeInt === 0 || typeInt === 1) {
        form?.setFieldsValue({ type: typeInt });
      }
      form.validateFields(["type"]); //
    },
    [form]
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <TypedFormItem
        name="name"
        label="Namn"
        rules={[{ required: true, message: "Vänligen ange ett listnamn" }]}
      >
        <Input />
      </TypedFormItem>
      <TypedFormItem
        name="type"
        label="Produkttyp"
        rules={[{ required: true, message: "Välj produkttyp" }]}
      >
        <TypeSelect
          currentType={undefined}
          onTypeChange={handleSelectProductType}
        />
      </TypedFormItem>
      <TypedFormItem name="createdById" label="Skapad av">
        <Tooltip title={`User ID: ${identity?.id}`}>
          <div
            style={{
              padding: "4px 11px",
              border: "1px solid #d9d9d9",
              borderRadius: "2px",
              backgroundColor: "#f5f5f5",
              color: "rgba(0, 0, 0, 0.65)",
            }}
          >
            {identity?.username || identity?.email || "Unknown Username"}
          </div>
        </Tooltip>
      </TypedFormItem>

      <TypedFormItem
        name="assignedToId"
        label="Tilldelad användare"
        rules={[
          {
            required: false,
            message: "Vänligen tilldela listan till en användare",
          },
        ]}
      >
        <UserSelect
          currentAssignedId={form.getFieldValue("assignedToId")}
          onUserAssignChange={handleAssignUserSelect}
          placeholder="Välj användare"
        />
      </TypedFormItem>

      <TypedFormItem
        name="description"
        label="Beskrivning"
        rules={[{ required: false, message: "Vänligen ange en beskrivning" }]}
      >
        <Input.TextArea rows={4} />
      </TypedFormItem>

      <TypedFormItem name="dueDate" label="Färdig senast">
        <DatePicker />
      </TypedFormItem>

      <Form.Item>
        <MagicButton 
          text="Skapa arbetslista"
          type="primary"
          onClick={() => form.submit()}
          disabled={!formIsValid}
        >
        </MagicButton>
        
        {/* <Button type="primary" htmlType="submit" disabled={!formIsValid}>
          Skapa arbetslista
        </Button> */}
      </Form.Item>
    </Form>
  );
};

export default AddWorkTaskList;
