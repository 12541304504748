import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { WorkTask, WorkTaskUpdateDto } from "interfaces/apiTypes";
import { BaseItem } from "interfaces/baseTypes";
import { notification } from "antd";
import { useQueryClient } from "@tanstack/react-query";

const useWorkTaskStatus = () => {
  const { updateWorkTask, loading, errorMessage } = useWorkTaskOpenApi();
  const queryClient = useQueryClient();

  const setWorkTaskStatus = async (
    selectedWorkTask: BaseItem<WorkTask> | null,
    status: 0 | 1 | 2,
    invalidateQueryKeyAfterUpdate: string = "workTasks"
  ) => {
    if (!selectedWorkTask?.id || !selectedWorkTask?.item) {
      return;
    }
    const withStatusUpdate: WorkTaskUpdateDto = {
      ...selectedWorkTask?.item,
      status: status, // Updated to use the passed status
    };

    await updateWorkTask(selectedWorkTask.id, withStatusUpdate);
    
    if (!loading && !errorMessage) {
      notification.success({
        message: "Liststatus uppdaterad",
        description: `Lista: "${selectedWorkTask?.item?.name}" har uppdaterats till status ${status}.`,
        placement: "topRight",
      });
    }
    if (errorMessage) {
      notification.error({
        message: "Fel vid statusuppdatering",
        description: `Lista: "${selectedWorkTask?.item?.name}" har inte uppdaterats till status ${status}.`,
        placement: "topRight",
      });
    }
    queryClient.invalidateQueries({ queryKey: [invalidateQueryKeyAfterUpdate] });
  };

  return { setWorkTaskStatus, errorMessage, loading };
};

export default useWorkTaskStatus;
