import FullScreenLoader from "components/common/loadIndicators/fullScreenLoader";
import usePriceCheckStore from "stores/priceCheckStore";

const PriceCheckFullScreenLoader = () => {
  const { globalLoading } = usePriceCheckStore();

  return (
    <FullScreenLoader loading={globalLoading?.isLoading} message={`${globalLoading?.message}`}/>
  );
}

export default PriceCheckFullScreenLoader;