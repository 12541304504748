import { BaseKey } from "@refinedev/core";
import { create } from "zustand";
import { PriceTracker, PriceTrackerItem } from "interfaces/apiTypes";
import { FilterItem } from "interfaces/baseTypes";

export type ActiveModalType =
  | "create-new-price-comparisons"
  | "price-check-information"
  | null;

export type SelectedItem = {
  mainItem: PriceTracker;
  comparisonItem: PriceTrackerItem | undefined;
};

type SelectedUpdateItem = {
  productId: number;
  itemNo: string | null | undefined;
};

type PriceCheckStore = {
  // Query or id for fetching data
  currentQuery: FilterItem[]; // Updated to be a list of FilterItem
  setCurrentQuery: (query: FilterItem[]) => void; // Updated to accept a list of FilterItem

  // Datasource
  dataSource: PriceTracker[];
  addDataToDataSource: (data: PriceTracker[]) => void;
  setDataSource: (data: PriceTracker[]) => void;

  updateDataSourceIsLoading: boolean;
  setUpdateDataSourceIsLoading: (isLoading: boolean) => void;
  updateDataSourceError: {
    isError: boolean;
    errorMessage: string;
    error?: any;
  };
  setDataSourceIsLoading: (isLoading: boolean) => void;
  dataSourceIsLoading: boolean;
  dataSourceError: { isError: boolean; errorMessage: string; error?: any };

  setDataSourceError: (error: {
    isError: boolean;
    errorMessage: string;
    error?: any;
  }) => void;
  setUpdateDataSourceError: (error: {
    isError: boolean;
    errorMessage: string;
    error?: any;
  }) => void;

  // Selected items
  selectedPriceHits: Record<BaseKey, SelectedItem>;
  setSelectedPriceHits: (selectedItems: Record<BaseKey, SelectedItem>) => void;
  
  selectedUpdateItems: Record<BaseKey, SelectedUpdateItem>;
  addSelectedPriceHit: (
    itemId: BaseKey,
    mainItem: PriceTracker,
    comparisonItem: PriceTrackerItem
  ) => void;
  removeSelectedPriceHit: (itemId: BaseKey) => void;
  toggleSelectedPriceHit: (
    itemId: BaseKey,
    mainItem: PriceTracker,
    comparisonItem: PriceTrackerItem
  ) => void;

  // Selected updateItems
  setSelectedUpdateItems: (
    selectedUpdateItems: Record<BaseKey, SelectedUpdateItem>
  ) => void;
  addSelectedUpdateItem: (itemId: BaseKey, mainItem: PriceTracker) => void;
  removeSelectedUpdateItem: (itemId: BaseKey) => void;
  updateSelectedPriceHit: (
    itemId: BaseKey,
    comparisonItem: PriceTrackerItem
  ) => void;
  toggleSelectedUpdateItem: (itemId: BaseKey, mainItem: PriceTracker) => void;

  // Modals
  activeModal: ActiveModalType;
  setActiveModal: (activeModal: ActiveModalType) => void;

  // Loading
  globalLoading: { isLoading: boolean; message: string };
  setGlobalLoading: (isLoading: boolean, message?: string) => void;

  // List drawer
  selectedListIsOpen: boolean;
  setSelectedPriceHitListIsOpen: (isOpen: boolean) => void;
  toggleSelectedPriceHitListIsOpen: () => void;

  // Reset
  resetState: (options?: { keepCurrentQuery?: boolean }) => void; // Updated to accept an options object
};

// Initial state definition
const initialState = {
  currentQuery: [],
  dataSource: [],
  dataSourceIsLoading: false,
  dataSourceError: { isError: false, errorMessage: "", error: null },
  updateDataSourceIsLoading: false,
  updateDataSourceError: { isError: false, errorMessage: "", error: null },
  selectedPriceHits: {},
  selectedUpdateItems: {},
  selectedListIsOpen: false,
  activeModal: null,
  globalLoading: {
    isLoading: false,
    message: "",
  },
};

const usePriceCheckStore = create<PriceCheckStore>((set, get) => ({
  ...initialState,

  setCurrentQuery: (query: FilterItem[]) => { // Updated to accept a list of FilterItem
    set(() => ({
      currentQuery: query,
    }));
  },

  // Data source
  setDataSource: (newData) => {
    set(() => ({
      dataSource: newData, // Directly replace the current dataSource with newData
    }));
  },

  addDataToDataSource: (newData) => {
    set((state) => {
      const { dataSource } = state;
      const updatedDataSource = [...dataSource, ...newData];
      return {
        dataSource: updatedDataSource,
      };
    });
  },

  setDataSourceIsLoading: (isLoading) =>
    set({ dataSourceIsLoading: isLoading }),

  setDataSourceError: ({ isError, errorMessage, error }) =>
    set({ dataSourceError: { isError, errorMessage, error } }),

  setUpdateDataSourceIsLoading: (isLoading) =>
    set({ updateDataSourceIsLoading: isLoading }),

  setUpdateDataSourceError: ({ isError, errorMessage, error }) =>
    set({ updateDataSourceError: { isError, errorMessage, error } }),

  // Selected update items
  setSelectedUpdateItems: (selectedUpdateItems) => set({ selectedUpdateItems }),

  addSelectedUpdateItem: (itemId, mainItem) =>
    set((state) => ({
      selectedUpdateItems: {
        ...state.selectedUpdateItems,
        [itemId]: {
          productId: mainItem.id,
          itemNo: mainItem?.item?.itemNo,
        },
      },
    })),

  removeSelectedUpdateItem: (itemId) =>
    set((state) => {
      const newSelectedUpdateItems = { ...state.selectedUpdateItems };
      delete newSelectedUpdateItems[itemId];
      return { selectedUpdateItems: newSelectedUpdateItems };
    }),

  toggleSelectedUpdateItem: (itemId, mainItem) =>
    set((state) => {
      const existingSelectedUpdateItem = state.selectedUpdateItems[itemId];
      if (existingSelectedUpdateItem) {
        const newSelectedUpdateItems = { ...state.selectedUpdateItems };
        delete newSelectedUpdateItems[itemId];
        return { selectedUpdateItems: newSelectedUpdateItems };
      } else {
        return {
          selectedUpdateItems: {
            ...state.selectedUpdateItems,
            [itemId]: {
              productId: mainItem.id,
              itemNo: mainItem.item?.itemNo,
            },
          },
        };
      }
    }),

  // Selected items
  setSelectedPriceHits: (selectedItems) => set({ selectedPriceHits: selectedItems }), // Added method

  addSelectedPriceHit: (itemId, mainItem, comparisonItem) =>
    set((state) => ({
      selectedPriceHits: {
        ...state.selectedPriceHits,
        [itemId]: { mainItem, comparisonItem },
      },
    })),

  removeSelectedPriceHit: (itemId) =>
    set((state) => {
      const newSelectedItems = { ...state.selectedPriceHits };
      delete newSelectedItems[itemId];
      return { selectedPriceHits: newSelectedItems };
    }),

  updateSelectedPriceHit: (itemId, comparisonItem) =>
    set((state) => {
      if (state.selectedPriceHits[itemId]) {
        return {
          selectedPriceHits: {
            ...state.selectedPriceHits,
            [itemId]: {
              ...state.selectedPriceHits[itemId],
              comparisonItem,
            },
          },
        };
      } else {
        console.warn(`Item with ID ${itemId} is not selected.`);
        return {}; // No state change
      }
    }),

  toggleSelectedPriceHit: (itemId, mainItem, comparisonItem) =>
    set((state) => {
      const existingSelectedItem = state.selectedPriceHits[itemId];
      if (existingSelectedItem) {
        if (existingSelectedItem?.comparisonItem?.id === comparisonItem.id) {
          const newSelectedItems = { ...state.selectedPriceHits };
          delete newSelectedItems[itemId];
          return { selectedPriceHits: newSelectedItems };
        } else {
          return {
            selectedPriceHits: {
              ...state.selectedPriceHits,
              [itemId]: {
                ...existingSelectedItem,
                comparisonItem,
              },
            },
          };
        }
      } else {
        return {
          selectedPriceHits: {
            ...state.selectedPriceHits,
            [itemId]: { mainItem, comparisonItem },
          },
        };
      }
    }),

  // List drawer
  setSelectedPriceHitListIsOpen: (isOpen) => set({ selectedListIsOpen: isOpen }),
  toggleSelectedPriceHitListIsOpen: () =>
    set((state) => ({ selectedListIsOpen: !state.selectedListIsOpen })),

  // Modals
  setActiveModal: (activeModal) => set({ activeModal }),

  // Loading
  setGlobalLoading: (isLoading, message = "") => {
    set({
      globalLoading: {
        isLoading,
        message, // Set the message, defaulting to empty string if not provided
      },
    });
  },

  // Reset
  resetState: ({
    keepCurrentQuery = false,
  }: { keepCurrentQuery?: boolean } = {}) =>
    set((state) => ({
      ...initialState,
      currentQuery: keepCurrentQuery ? state.currentQuery : [], // Conditionally keep or reset currentQuery
    })),
}));

export default usePriceCheckStore;
