import React, { useCallback, useEffect } from "react";
import { Button, DatePicker, Form, Input, Tooltip } from "antd";
import type { FormItemProps } from "antd";
import { IUser } from "interfaces";
import { useGetIdentity, HttpError } from "@refinedev/core";
import { useForm } from "antd/lib/form/Form";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { useNotification } from "@refinedev/core";
import {
  ClientNotReadyError,
  WorkTaskUpdateDto,
} from "interfaces/apiTypes";
import WorkTaskStatusSelect from "components/common/select/workTaskStatusSelect";
import UserSelect from "components/common/select/userSelect";
import dayjs from "dayjs";

function TypedFormItem<K extends keyof WorkTaskUpdateDto>(
  props: FormItemProps<WorkTaskUpdateDto[K]> & { name: K }
) {
  return <Form.Item {...props} />;
}

interface UpdateWorkTaskListProps {
  taskId: number;
  onUpdateSuccess?: () => void;
  onUpdateFailure?: (error: any) => void;
}

const UpdateWorkTaskList: React.FC<UpdateWorkTaskListProps> = ({
  taskId,
  onUpdateSuccess,
  onUpdateFailure,
}) => {
  const { open } = useNotification();
  const [form] = useForm<WorkTaskUpdateDto>();
  const { data: identity } = useGetIdentity<IUser>();
  const [formIsValid, setFormIsValid] = React.useState<boolean>(false);
  const watchedFormValues = Form.useWatch([], form);

  const { updateWorkTask, errorMessage, loading, getWorkTaskById } =
    useWorkTaskOpenApi();

  useEffect(() => {
    const fetchTask = async () => {
      if (!form || !taskId) return; // Check if form is available before fetching

      try {
        const task = await getWorkTaskById(taskId);
        if (!task || !task.value) {
          console.error(
            "Failed to fetch task or task structure is invalid",
            task
          );
          return;
        }
        form.setFieldsValue({
          name: task.value.item.name,
          description: task.value.item.description,
          dueDate: task.value.item.dueDate,
          assignedToId: task.value.item.assignedToId,
          status: task.value.item.status,
        });
      } catch (e) {
        if (e instanceof ClientNotReadyError) {
          console.error("Client not ready", e);
        } else {
          console.error("Error fetching task", e);
        }
      }
    };
    fetchTask();
  }, [taskId, form]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setFormIsValid(true))
      .catch(() => setFormIsValid(false));
  }, [form, watchedFormValues]);

  const handleSubmit = async (values: WorkTaskUpdateDto) => {
    await updateWorkTask(taskId, values);
    if (errorMessage) {
      onUpdateFailure?.(errorMessage);
      open?.({
        message: "Misslyckades med att uppdatera arbetslista.",
        type: "error",
      });
    }
    if (!loading && !errorMessage) {
      open?.({
        message: "Arbetslista uppdaterad.",
        type: "success",
      });
      onUpdateSuccess?.();
    }
  };

  const handleAssignUserSelect = useCallback(
    (userId: string | undefined) => {
      form?.setFieldsValue({ assignedToId: userId });
    },
    [form]
  );

  const handleStatusChange = useCallback(
    (status: number | undefined) => {
      form?.setFieldsValue({ status: status as 0 | 1 | 2 });
    },
    [form]
  );

  return (
    <Form<WorkTaskUpdateDto>
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <TypedFormItem
        name="name"
        label="Namn"
        rules={[{ required: true, message: "Vänligen ange ett listnamn" }]}
      >
        <Input />
      </TypedFormItem>
      <Form.Item label="Skapad av">
        <Tooltip title={`User ID: ${identity?.id}`}>
          <div
            style={{
              padding: "4px 11px",
              border: "1px solid #d9d9d9",
              borderRadius: "2px",
              backgroundColor: "#f5f5f5",
              color: "rgba(0, 0, 0, 0.65)",
            }}
          >
            {identity?.username || identity?.email || "Unknown Username"}
          </div>
        </Tooltip>
      </Form.Item>
      <TypedFormItem name="assignedToId" label="Tilldelad användare">
        <UserSelect
          currentAssignedId={form.getFieldValue("assignedToId")}
          onUserAssignChange={handleAssignUserSelect}
          placeholder="Välj användare"
        />
      </TypedFormItem>
      <TypedFormItem
        name="description"
        label="Beskrivning"
        rules={[{ required: false, message: "Vänligen ange en beskrivning" }]}
      >
        <Input.TextArea rows={4} />
      </TypedFormItem>

      <TypedFormItem name="status" label="Status">
        <WorkTaskStatusSelect
          currentStatus={form.getFieldValue("status")}
          onStatusChange={handleStatusChange}
        />
      </TypedFormItem>

      <TypedFormItem
        name="dueDate"
        label="Färdig senast"
        getValueProps={(value) => ({
          value: value ? dayjs(value) : null,
        })}
      >
        <DatePicker />
      </TypedFormItem>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={!formIsValid}>
          Uppdatera arbetslista
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdateWorkTaskList;
