import SearchableSelect from "components/common/select/SearchableSelect";
import { IBrand } from "interfaces";
import useMetadataStore from "stores/metadataStore";
interface BrandSelectProps {
  editorProps: any;
  placeholder?: string;
}

const BrandSelectGrid = ({ editorProps, placeholder }: BrandSelectProps) => {
  const { metadata } = useMetadataStore();
  const { brands = [] } = metadata ?? {};
  const brandOptions = brands?.map((brand: IBrand) => ({
    value: brand?.id,
    label: brand?.name,
  }));

  const onChange = async (value: string) => {
    await editorProps.onChange(value);
    editorProps.onComplete();
  };

  return (
    <SearchableSelect
      defaultOpen={true}
      options={brandOptions}
      onChange={onChange}
      placeholder={placeholder ?? ""}
      isTableCell={true}
    />
  );
};

export default BrandSelectGrid;