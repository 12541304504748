import React, { useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Select, Space } from 'antd';
import { HttpError, useList } from "@refinedev/core";
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { ILanguage } from 'interfaces';
import { LOCALE_RES_ID } from 'utility/constants';
import { useLocaleStore } from 'stores/localeStore';


export const LangDataDropdown: React.FC = () => {
  const { locale, setLanguages, setActiveLanguageByLocale, activeLanguage } = useLocaleStore();
  const currentLocale = locale;

  const { data, isLoading, isError } = useList<ILanguage, HttpError>({
    resource: LOCALE_RES_ID,
  });

  useEffect (() => {
    setLanguages(data?.data || []);
  }, [data]);

  useEffect(() => {
    if (!activeLanguage) {
      setActiveLanguageByLocale("sv");
    }
  }, [activeLanguage]);

  const changeLanguage = (locale : string) => {
    setActiveLanguageByLocale(locale);
  }
  
  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {data?.data.map((language) => {
        const lang = language.locale;
        return (
          <Menu.Item
            key={lang}
            onClick={() => changeLanguage(lang)}
            icon={
              <span style={{ marginRight: 8 }}>
                <ReactCountryFlag
                  countryCode={lang == "sv" ? "SE" : lang == "da" ? "DK" : "NO"}
                  svg
                  style={{
                    width: "1.3em",
                    height: "1.3em",
                    marginBottom: "2px",
                  }}
                />
              </span>
            }
          >
            {lang === "sv" ? "Svenska" : lang == "da" ? "Danska" : "Norska"}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <a>
        <Space>
          <ReactCountryFlag
            countryCode={currentLocale == 'sv' ? 'SE' : currentLocale == 'da' ? 'DK' : "NO"}
            svg
            style={{
              width: "1.3em",
              height: "1.3em",
              marginBottom: "2px",
            }}
          />
          {currentLocale === "sv" ? "Svenska" : currentLocale === "da" ? "Danska" : "Norska"}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};