import styled from "@emotion/styled";
import { Badge } from "antd";
import usePriceCheckStore from "stores/priceCheckStore";

const UpdateItemsCounter = styled("div")`
  height: 100%;
  &.selected-products-count {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  .ant-badge {
    height: 100%;
    min-width: 40px;
  }

  .custom-badge-empty {
    border: 1px solid #dddddd;
    min-width: 40px;
    width: 100%;
    height: 100%;
    min-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 0.12rem;
  }
  .custom-badge-empty.disabled {
    color: rgba(0, 0, 0, 0.25);
  }
`;

const PriceCheckUpdateItemsCounter = () => {
  const { selectedUpdateItems } = usePriceCheckStore();

  const count = selectedUpdateItems
    ? Object.keys(selectedUpdateItems).length
    : 0;
  return (
    <UpdateItemsCounter>
      <span className="selected-products-count">
        {count ? (
          <Badge
            count={count}
            overflowCount={100}
            style={{
              backgroundColor: "#F6FFED",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              color: "black",
            }}
          />
        ) : (
          <div
            className={`custom-badge-empty ${count === 0 ? "disabled" : ""}`}
          >
            0
          </div>
        )}
      </span>
    </UpdateItemsCounter>
  );
};

export default PriceCheckUpdateItemsCounter;
