import { Button, Card, Flex } from "antd";
import styled from "@emotion/styled";
import PriceCheckToolBarButtons from "../sections/priceCheckButtonsSection";
import PriceCheckFilterSection from "../sections/priceCheckFilterSection";
import usePriceCheckStore from "stores/priceCheckStore";
import { InfoCircleFilled } from "@ant-design/icons";

const StyledCard = styled(Card)`
  .ant-card-body {
    /* padding: 24px 24px 10px 24px; */
  }
`;
const ToolBarWrapper = styled(Flex)`
  flex-wrap: wrap;
  /* padding: 12px 8px 12px 8px; */
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  row-gap: 8px;
`;

const ToolBarPriceCheck = () => {
  const { setActiveModal, activeModal } = usePriceCheckStore();
  return (
    <StyledCard
      style={{ backgroundColor: "white", marginBottom: "18px" }}
      bordered={false}
      className="criclebox h-full"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "1rem" }}>
          <h1
            style={{
              fontFamily: "HK Grotesk Bold",
              color: "rgba(0, 0, 0, 0.88)",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "1.4",
              margin: "auto 0",
            }}
          >
            {" "}
            Priskoll
          </h1>
        </div>
        <Button
          onClick={() => setActiveModal('price-check-information')}
          type="text"
          size="middle"
          icon={
            <InfoCircleFilled style={{ fontSize: "23px", color: "#195178" }} />
          }
        ></Button>
      </div>
      <ToolBarWrapper justify="space-between">
        <PriceCheckFilterSection />
        <PriceCheckToolBarButtons />
      </ToolBarWrapper>
    </StyledCard>
  );
};

export default ToolBarPriceCheck;
