import React, { RefObject, useEffect } from "react";
import { Card, Space, Button, Tooltip } from "antd";
// import { PriceTrackerItem, PriceTrackerItemDto } from "interfaces";
import {
  CalendarArrowDown,
  CircleMinus,
  CirclePlus,
  ExternalLink,
  Image,
  Megaphone,
  RefreshCwOff,
  ShieldCheck,
} from "lucide-react";
import {
  calculatePercentageDiff,
  formatDateWithTime,
} from "utility/helper";

import useIsSticky from "components/hooks/ui/useIsSticky";
import PriceCheckStickyButtons from "components/priceCheck/buttons/priceCheckStickyButtons";
import PriceCheckListDrawer from "components/priceCheck/drawers/selectedList/priceCheckListDrawer";
import usePriceCheckStore from "stores/priceCheckStore";

import { PRICE_CHECK_THEME_COLORS } from "utility/priceCheck/constants";
import {
  CardEntityContent,
  CompareEntitiesWrapper,
  MainEntityInformation,
  MainEntityPropsList,
  MainEntityWrapper,
  PriceCheckWrapper,
  PriceWidget,
  PriceMainWidget,
} from "components/priceCheck/styles/priceCheckStyles";

import PriceCheckFullScreenLoader from "components/priceCheck/loaders/priceCheckFullScreenLoader";
import ControlledCheckBox from "components/common/checkBoxes/controlledCheckBox";
import { InfiniteScrollStatusDisplay } from "components/common/status/infiniteScrollStatusDisplay";
import usePriceCheckInfiniteScroll from "components/priceCheck/hooks/usePriceCheckInfiniteScroll";
import PriceCheckModals from "components/priceCheck/modals/PriceCheckModals";
import { useLocaleStore } from "stores/localeStore";
import ToolBarPriceCheck from "components/priceCheck/toolBar";
import { enhanceProduct, Product, PriceTrackerItem, TaskTypeEnum} from "interfaces/apiTypes";
import WorkTaskDrawer from "components/workTask/drawers/workTaskDrawer";
import useWorkTaskStore from "stores/workTaskStore";
import AddWorkTaskList from "components/workTask/drawers/addWorkTaskList";
import { useQueryClient } from "@tanstack/react-query";
const STICKY_BUTTONS_ID = "sticky-buttons-wrapper";

const PriceCheckView: React.FC = () => {
  const { currency, activeLanguage } = useLocaleStore();
  const isSticky = useIsSticky(STICKY_BUTTONS_ID, 16);
  const {
    selectedPriceHits: selectedItems,
    toggleSelectedPriceHit: toggleSelectedItem,
    selectedListIsOpen,
    setSelectedPriceHitListIsOpen: setSelectedListIsOpen,
    dataSource,
    selectedUpdateItems,
    toggleSelectedUpdateItem,
    resetState,
  } = usePriceCheckStore();

  const {createTaskIsOpen, setCreateTaskIsOpen } = useWorkTaskStore();
  const queryClient = useQueryClient();

  const {
    infiniteItemsIsLoading,
    infiniteItemsLength,
    infiniteScrollError,
    hasMoreItems,
    totalPages,
    fetchMoreData,
    refetch,
    targetRef,
  } = usePriceCheckInfiniteScroll();

  useEffect(() => {
    // Reset zustand usePriceCheckStore on component unmount
    return () => {
      resetState();
    };
  }, [resetState]);

  const onWorkTaskCreateHandler = () => {
    setCreateTaskIsOpen(false);
    queryClient.invalidateQueries(['workTasksSelect']);
  }

  return (
    <>
      <div id={"price-check-wrapper"} style={{ position: "relative" }}>
        <div>
          <ToolBarPriceCheck />
        </div>
        <PriceCheckWrapper>
          <PriceCheckStickyButtons
            formLoading={false}
            isInitalLoading={false}
            // saveButtonProps={}
            hideBtns={!isSticky}
            compId={STICKY_BUTTONS_ID}
          />
          <Space
            wrap
            direction="vertical"
            size={18}
            style={{ display: "flex" }}
          >
            <>
              {dataSource?.map((dataSourceItem) => {
                const dataSourceItemId = dataSourceItem?.id;
                const {
                  itemNo,
                  priceTrackerItems,
                  latestLookup,
                  prisjaktProductUrl,
                } = dataSourceItem?.item;

                const product = enhanceProduct(dataSourceItem?.item.product, activeLanguage?.id);
                
                const mainProductIsSynced = dataSourceItem?.item.product !== null;
                // @ts-ignore
                const mainProductSyncedPrice = product?.getSalePrice();
                const latestLookupDateDetailed =
                  formatDateWithTime(latestLookup);

                const mainRedirectData = {
                  urlOrMessage:
                    prisjaktProductUrl && prisjaktProductUrl?.trim()
                      ? prisjaktProductUrl
                      : null,
                  validUrl: !!(
                    prisjaktProductUrl && prisjaktProductUrl?.trim()
                  ),
                };

                const handleComparisonItemClick = (
                  comparisonItem: PriceTrackerItem
                ) => {
                  toggleSelectedItem(dataSourceItemId, dataSourceItem, comparisonItem);
                };

                const handleUpdateItemClick = () => {
                  toggleSelectedUpdateItem(dataSourceItemId, dataSourceItem);
                };

                const isUpdateItemSelected =
                  selectedUpdateItems[dataSourceItemId]?.productId === dataSourceItemId;

                return (
                  <Card
                    style={{
                      backgroundColor: PRICE_CHECK_THEME_COLORS.DEFAULT_CARD_BG,
                    }}
                    bordered={false}
                    className="criclebox h-full"
                  >
                    <CardEntityContent>
                      <MainEntityWrapper>
                        <div className="main-entity-content">
                          <div className="main-entity-information-wrapper">
                            <MainEntityInformation>
                              <div className="main-entity-top-area-content">
                                <Tooltip
                                  color={PRICE_CHECK_THEME_COLORS.STATE.SUCCESS}
                                  title={"Lägg till i uppdateringslistan"}
                                  mouseEnterDelay={0.8}
                                  mouseLeaveDelay={0.0}
                                >
                                  <ControlledCheckBox
                                    name="generate-item"
                                    id={`checkbox-${dataSourceItemId}`}
                                    checked={isUpdateItemSelected} // Control checked state
                                    onChange={handleUpdateItemClick} // Handle click event
                                  />
                                </Tooltip>
                                <span className="main-entity-title line-clamp-text">
                                  <span>{itemNo}</span>{" "}
                                  <span
                                    style={{
                                      color: `${PRICE_CHECK_THEME_COLORS.DISCREET_TEXT}`,
                                    }}
                                  >
                                    {/* - {mainProductName} */}
                                  </span>
                                </span>
                              </div>

                              <div className="main-entity-grid-bottom-area-content">
                                <div className="main-entity-grid-area-content-image">
                                  {product.getImageUrl() ? (
                                    <img
                                      className="product-image"
                                      alt="bild"
                                      src={product.getImageUrl()}
                                    />
                                  ) : (
                                    <Image
                                      size={70}
                                      color={
                                        PRICE_CHECK_THEME_COLORS.DISCREET_TEXT
                                      }
                                    ></Image>
                                  )}
                                </div>
                                <div className="main-entity-grid-area-content-information">
                                  <MainEntityPropsList>
                                    {mainProductIsSynced && (
                                      <>
                                        <li
                                          style={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          <span className="prop-name">
                                            Namn:
                                          </span>{" "}
                                          <span className="product-name">
                                            {product.getName()}
                                          </span>
                                        </li>
                                        <li>
                                          <span className="prop-name">
                                            Ean:
                                          </span>{" "}
                                          {product.getEan()}
                                        </li>
                                      </>
                                    )}
                                    <li>
                                      <span className="prop-name">
                                        Sökterm:
                                      </span>{" "}
                                      {dataSourceItem?.item.latestSearchTerm}
                                    </li>
                                    <li>
                                      <span className="prop-name">Lev:</span>{" "}
                                      {dataSourceItem?.item.supplier}
                                    </li>
                                    <li>
                                      <span className="prop-name">Lev Nr:</span>{" "}
                                      {dataSourceItem?.item.supplierNo}
                                    </li>
                                  </MainEntityPropsList>
                                </div>
                              </div>
                            </MainEntityInformation>
                          </div>
                          <div className="main-entity-price-widget-wrapper">
                            <PriceMainWidget
                              className="main-widget"
                              style={{
                                backgroundColor:
                                  PRICE_CHECK_THEME_COLORS.DEFAULT_CARD_BG,
                              }}
                            >
                              <div className="main-widget-content">
                                <ul className="main-widget-list">
                                  <li>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "1.3rem",
                                          fontFamily: "HK Grotesk Bold",
                                        }}
                                      >
                                        {mainProductSyncedPrice ? (
                                          <>
                                            {" "}
                                            <span
                                              style={{
                                                color:
                                                  PRICE_CHECK_THEME_COLORS.PRIMARY_TEXT,
                                              }}
                                            >
                                              {mainProductSyncedPrice}{" "}
                                            </span>{" "}
                                            <span className="currency">
                                              {currency}{" "}
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            {!product.getSalePrice() ? (
                                              <span
                                                style={{
                                                  opacity: "0.2",
                                                  textDecorationLine:
                                                    "line-through",
                                                }}
                                              >
                                                {"Inget pris"}
                                              </span>
                                            ) : (
                                              <span
                                              >
                                              {product.getSalePrice()}{" "}{currency}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </div>{" "}
                                      <Tooltip
                                        color={
                                          mainProductIsSynced
                                            ? PRICE_CHECK_THEME_COLORS.STATE
                                                .SUCCESS
                                            : PRICE_CHECK_THEME_COLORS.STATE
                                                .FAIL
                                        }
                                        title={
                                          mainProductIsSynced
                                            ? "Produkt finns i PIM"
                                            : "Produkten finns inte i PIM"
                                        }
                                      >
                                        <span
                                          className={`main-percentage-diff ${
                                            mainProductIsSynced
                                              ? "success-state"
                                              : "fail-state"
                                          }`}
                                        >
                                          {mainProductIsSynced ? (
                                            <ShieldCheck
                                              color={
                                                PRICE_CHECK_THEME_COLORS.STATE
                                                  .SUCCESS
                                              }
                                            />
                                          ) : (
                                            <RefreshCwOff
                                              color={
                                                PRICE_CHECK_THEME_COLORS.STATE
                                                  .FAIL
                                              }
                                            />
                                          )}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </li>
                                </ul>
                                <li>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    {/* const latestLookupDateDetailed = formatDateWithTime(latestLookup); */}
                                    <Tooltip
                                      mouseEnterDelay={0.8}
                                      mouseLeaveDelay={0.0}
                                      color={
                                        mainRedirectData?.validUrl
                                          ? PRICE_CHECK_THEME_COLORS.PRIMARY
                                          : PRICE_CHECK_THEME_COLORS.DEFAULT_TOOLTIP
                                      }
                                      title={latestLookupDateDetailed}
                                    >
                                      {" "}
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          columnGap: "0.2em",
                                          fontSize: "1rem",
                                        }}
                                        className="date"
                                      >
                                        <CalendarArrowDown
                                          size={"1em"}
                                          style={{ marginTop: "-0.2em" }}
                                        />
                                        <span>
                                          {new Date(
                                            latestLookup
                                          ).toLocaleDateString("sv-SE")}
                                        </span>
                                      </span>
                                    </Tooltip>

                                    <Tooltip
                                      mouseEnterDelay={0.8}
                                      mouseLeaveDelay={0.0}
                                      color={
                                        mainRedirectData?.validUrl
                                          ? PRICE_CHECK_THEME_COLORS.PRIMARY
                                          : PRICE_CHECK_THEME_COLORS.DEFAULT_TOOLTIP
                                      }
                                      title={
                                        mainRedirectData?.urlOrMessage ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontFamily: "HK Grotesk Bold",
                                              }}
                                            >
                                              Gå till URL:
                                            </span>
                                            <span>
                                              {mainRedirectData?.urlOrMessage}
                                            </span>
                                          </div>
                                        ) : (
                                          "Ingen url"
                                        )
                                      }
                                    >
                                      <Button
                                        type="link"
                                        disabled={!mainRedirectData?.validUrl}
                                        className="icon-button-discreet"
                                        onClick={() => {
                                          if (mainRedirectData?.validUrl) {
                                            window.open(
                                              mainRedirectData?.urlOrMessage ??
                                                "",
                                              "_blank"
                                            );
                                          }
                                        }}
                                      >
                                        <span className="icon-container">
                                          <ExternalLink
                                            strokeWidth={2}
                                            className={`${
                                              mainRedirectData?.validUrl
                                                ? "default-link-state"
                                                : "fail-link-state"
                                            }`}
                                          />
                                        </span>
                                      </Button>
                                    </Tooltip>
                                  </div>
                                </li>
                              </div>
                            </PriceMainWidget>
                          </div>
                        </div>
                      </MainEntityWrapper>

                      <CompareEntitiesWrapper id={dataSourceItemId?.toString()}>
                        {priceTrackerItems && priceTrackerItems.length > 0 ? (

                          <div className="compare-entities-content">
                            {priceTrackerItems.sort((a, b) => a.price - b.price).map((trackedItem, index) => {
                              const {
                                price: trackedPrice,
                                companyName: trackedCompanyName,
                                isAdHit,
                                productName,
                              } = trackedItem;
                              const trackedLatestLookup =
                                dataSourceItem?.item?.latestLookup;
                              const trackedDifferencePercentage =
                                mainProductSyncedPrice
                                  ? calculatePercentageDiff(
                                      mainProductSyncedPrice,
                                      trackedPrice ?? 0
                                    )
                                  : null;
                              const isTrackedPriceLower = mainProductSyncedPrice
                                ? (trackedPrice ?? 0) < mainProductSyncedPrice
                                : false;

                              const isComparisonItemSelected =
                                selectedItems[dataSourceItemId]?.comparisonItem
                                  ?.id === trackedItem.id;

                              const trackedRedirectData = mainRedirectData;

                              return (
                                <PriceWidget
                                  key={
                                    trackedItem?.id +
                                    (trackedItem?.companyName ?? "")
                                  }
                                  // onClick={(event) =>
                                  //   handleClassNameClick(
                                  //     () => handleComparClick(trackedItem),
                                  //     event,
                                  //     "price-widget-list"
                                  //   )
                                  // }
                                  className={`comparison-item ${
                                    isComparisonItemSelected
                                      ? "price-widget-selected"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="price-widget-content"
                                    onClick={() =>
                                      handleComparisonItemClick(trackedItem)
                                    }
                                  >
                                    <ul className="price-widget-list">
                                      <li>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "start",
                                            paddingBottom: "0.5em",
                                          }}
                                        >
                                          <span className="compare-entity-title">
                                            {trackedCompanyName}
                                          </span>

                                          <Tooltip
                                            mouseEnterDelay={0.8}
                                            mouseLeaveDelay={0.0}
                                            // color={}
                                            title={`${
                                              isComparisonItemSelected
                                                ? `Avmarkera ${trackedCompanyName}`
                                                : `Markera${trackedCompanyName}`
                                            } `}
                                          >
                                            {isComparisonItemSelected ? (
                                              <CircleMinus
                                                className="icon-button"
                                                size={"1.6rem"}
                                                strokeWidth={2}
                                              />
                                            ) : (
                                              <CirclePlus
                                                size={"1.6rem"}
                                                strokeWidth={2}
                                                className="icon-button"
                                              />
                                            )}
                                          </Tooltip>
                                        </div>
                                      </li>

                                      <li>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span className="comparison-price default-cursor">
                                            <span>{trackedPrice}</span>{" "}
                                            <span className="currency">
                                              {currency}{" "}
                                            </span>
                                          </span>{" "}
                                          <span
                                            className={`percentage-diff default-cursor ${
                                              isTrackedPriceLower
                                                ? "comparison-higher"
                                                : "comparison-lower"
                                            }`}
                                          >
                                            {typeof trackedDifferencePercentage ===
                                            "number"
                                              ? `${trackedDifferencePercentage}%`
                                              : ""}
                                          </span>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="product-name-wrapper">
                                          <span className="product-name">
                                            {productName}
                                          </span>
                                        </div>
                                      </li>
                                    </ul>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          columnGap: "0.2em",
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        {
                                          isAdHit && <Megaphone color={PRICE_CHECK_THEME_COLORS.PRIMARY_TEXT}></Megaphone>
                                        }
                                      </span>

                                      <Tooltip
                                        mouseEnterDelay={0.8}
                                        mouseLeaveDelay={0.0}
                                        color={
                                          trackedRedirectData?.validUrl
                                            ? PRICE_CHECK_THEME_COLORS.PRIMARY
                                            : PRICE_CHECK_THEME_COLORS.DEFAULT_TOOLTIP
                                        }
                                        title={
                                          trackedRedirectData?.urlOrMessage ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontFamily: "HK Grotesk Bold",
                                                }}
                                              >
                                                Gå till URL:
                                              </span>
                                              <span>
                                                {
                                                  trackedRedirectData?.urlOrMessage
                                                }
                                              </span>
                                            </div>
                                          ) : (
                                            "Ingen url"
                                          )
                                        }
                                      >
                                        <Button
                                          type="link"
                                          disabled={
                                            !trackedRedirectData?.validUrl
                                          }
                                          className="icon-button-discreet"
                                          onClick={() => {
                                            if (trackedRedirectData?.validUrl) {
                                              window.open(
                                                trackedRedirectData.urlOrMessage ??
                                                  "",
                                                "_blank"
                                              );
                                            }
                                          }}
                                        >
                                          <span className="icon-container">
                                            <ExternalLink
                                              size={"1.1rem"}
                                              strokeWidth={2}
                                              className={`${
                                                trackedRedirectData?.validUrl
                                                  ? "default-link-state"
                                                  : "fail-link-state"
                                              }`}
                                            />
                                          </span>
                                        </Button>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </PriceWidget>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="empty-compare-entities-content">
                            <span>Inga prisjämförelser tillgängliga</span>
                          </div>
                        )}
                      </CompareEntitiesWrapper>
                    </CardEntityContent>
                  </Card>
                );
              })}
            </>
            {/* <div ref={targetRef}></div> */}
            <InfiniteScrollStatusDisplay
              isLoading={infiniteItemsIsLoading}
              itemsLength={infiniteItemsLength}
              error={infiniteScrollError}
              hasMoreItems={hasMoreItems}
              fetchMoreData={fetchMoreData}
              refetch={refetch}
              targetRef={targetRef}
              totalPages={totalPages} // Assuming page size is 10
            />
          </Space>
        </PriceCheckWrapper>
        {/* <div ref={targetRef}></div> */}
      </div>

      <PriceCheckListDrawer
        visible={selectedListIsOpen}
        onClose={() => setSelectedListIsOpen(false)}
      />
      <WorkTaskDrawer 
        isOpen={createTaskIsOpen} 
        title="Skapa arbetslista" 
        onClose={() => setCreateTaskIsOpen(false)}
        renderContent={() => <AddWorkTaskList onSubmitSuccess={onWorkTaskCreateHandler} />} 
      />
      <PriceCheckModals />
      <PriceCheckFullScreenLoader />
      {/* Modal for reporting post or update requests */}
      {/* <ReportModal /> */}
    </>
  );
};

export default PriceCheckView;
