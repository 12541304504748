import { useEffect, useRef, useState } from 'react';
import { useOpenApiClient } from './base/useOpenApiClient';
import { BaseItem, ErrorResponse, GetManyRequestOptions } from 'interfaces/baseTypes';
import { EntityMetaData, ResponseWrapperMany, Product } from 'interfaces/apiTypes';

export interface ProductOne {
    metaData: EntityMetaData;
    value: Product;
}

const useProductOpenApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ErrorResponse | null>(null);
    const {client, getClient} = useOpenApiClient();
    const clientRef = useRef(client);

    useEffect(() => {
        clientRef.current = client;
    }, [client]);

    async function getProductById(id: number): Promise<ProductOne | undefined> {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.GET('/api/productentity/{id}', {
                params: { path: { id } }
            });
            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
            return response?.data as ProductOne | undefined;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function getProducts(options: GetManyRequestOptions = {}): Promise<ResponseWrapperMany<BaseItem<Product>> | undefined> {
        const { page = 0, pageSize = 10, order, orderByField, filters } = options;
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.GET('/api/productentity', {
                params: { query: { page, pageSize, order, orderByField, filters } }
            });
            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
            return response?.data as ResponseWrapperMany<BaseItem<Product>>;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    // async function createProduct(product: ProductCreateDto): Promise<ProductOne | undefined> {
    //     setLoading(true);
    //     setErrorMessage(null);
    //     try {
    //         const response = await clientRef.current?.POST('/api/productentity', {
    //             body: product,
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const error = response?.error as unknown as ErrorResponse;
    //         if (error) {
    //             setErrorMessage(error);
    //             return undefined;
    //         }
    //         return response?.data as ProductOne;
    //     } catch (e: any) {
    //         setErrorMessage(e.message || 'An error occurred');
    //         return undefined;
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    // async function updateProduct(id: number, product: ProductCreateDto): Promise<ProductOne | undefined> {
    //     setLoading(true);
    //     setErrorMessage(null);
    //     try {
    //         const response = await clientRef.current?.PUT('/api/productentity/{id}', {
    //             params: { path: { id } },
    //             body: product,
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const error = response?.error as unknown as ErrorResponse;
    //         if (error) {
    //             setErrorMessage(error);
    //             return undefined;
    //         }
    //         return response?.data as ProductOne | undefined;
    //     } catch (e: any) {
    //         setErrorMessage(e.message || 'An error occurred');
    //         return undefined;
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    async function deleteProduct(id: number): Promise<void> {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            await clientRef.current?.DELETE('/api/productentity/{id}', {
                params: { path: { id } }
            });
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }
    
    async function getMetadata(): Promise<EntityMetaData | undefined>
    {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            const response = await clientRef.current?.GET('/api/productentity/metadata');

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
            return response?.data as EntityMetaData;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    return { getProducts, getProductById, deleteProduct, getMetadata, loading, errorMessage };
}

export default useProductOpenApi;

