import { Button } from "antd";
import React, { useCallback, useRef, useState } from "react";
import {
  ColumnConfig,
  DEFAULT_LIST_COL_HEIGHT_PX,
  SimpleListView,
} from "components/listViews/simpleListView";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { BaseItem, FilterItem, FilterOperatorEnum } from "interfaces/baseTypes";
import { SectionHeader } from "components/layout/header/sectionHeader";
import useWorkTaskStore from "stores/workTaskStore";
import { DividerLine } from "components/separators/dividerLine";
import { enhanceProduct, Product, WorkTask } from "interfaces/apiTypes";
import { ListChecks } from "lucide-react";
import WorkTaskSectionHeader from "./header/sectionHeader";
import { defaultSectionHeaderIconStyle } from "../styles/workTaskStyles";
import { addOrUpdateFilterItem } from "utility/helper";
import useProductOpenApi from "apiClients/useProductOpenApiClientV2";
import { useLocaleStore } from "stores/localeStore";
import { ILanguage } from "interfaces";

const getWorkTaskItemsColumns = (activeLanguage: ILanguage | null): ColumnConfig<BaseItem<Product>>[] => [
  {
    header: "ArtNr",
    accessorKey: "mainItemNo",
    cell: ({ row }) => (
      <>
       {(enhanceProduct(row.original.item, activeLanguage?.id)?.getItemNumber()) ?? "N/A"}
      </>
    ),
    size: 100,
    sort: true,
  },
  {
    header: "Namn",
    accessorKey: "name",
    cell: ({ row }) => (
      <>
        {enhanceProduct(row.original.item, activeLanguage?.id)?.getName?.() ?? "N/A"}
      </>
    ),
    size: 200,
  },
  {
    header: "Varumärke",
    accessorKey: "brand",
    cell: ({ row }) => (
      <>
        {enhanceProduct(row.original.item, activeLanguage?.id)?.brand?.name ?? "N/A"}
      </>
    ),
    size: 100,
  },
];

const WorkTaskItems: React.FC<{ sectionHeaderTitle?: string }> = ({
  sectionHeaderTitle,
}) => {
  const { currentFilterItems } = useWorkTaskStore();
  const { getProducts } = useProductOpenApi();
  const { activeLanguage } = useLocaleStore();
  const [totalCount, setTotalCount] = useState(0);

  const columns = getWorkTaskItemsColumns(activeLanguage);

  return (
    <div>
      <div style={{ position: "sticky", top: 0, zIndex: 11 }}>
        <WorkTaskSectionHeader
          title={sectionHeaderTitle ?? ""}
          subText={`(${totalCount}) st`}
          icon={<ListChecks style={defaultSectionHeaderIconStyle} />}
          showSeparatorLine={true}
        />
      </div>
      <SimpleListView<Product>
        queryKey="products"
        resizableColumns={false}
        selectable={false}
        onRowClick={(item) => {
          console.log("item", typeof item);
        }}
        onSelectionChange={(selectedItems) => {
          console.log("selectedItems", selectedItems);
        }}
        fetchItems={async (
          page,
          pageSize,
          sortOrder,
          orderByField,
          filterString
        ) =>
          await getProducts({
            page: page,
            pageSize: pageSize,
            order: sortOrder,
            orderByField: orderByField,
            filters: filterString,
          })
        }
        columns={columns}
        renderHeader={() => {
          return <React.Fragment></React.Fragment>;
        }}
        onCountChange={(count) => {
          setTotalCount(count);
        }}
        filterItems={currentFilterItems}
        listLoadingHeightPx={DEFAULT_LIST_COL_HEIGHT_PX * 5}
      />
    </div>
  );
};

export default WorkTaskItems;
