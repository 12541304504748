import React from "react";
import { Spin, Button } from "antd"; // Antd components
import styled from "@emotion/styled";
import { PRICE_CHECK_THEME_COLORS } from "utility/priceCheck/constants";
import { ArrowBigDownDash, RefreshCw } from "lucide-react";

interface ScrollProps {
  isLoading: boolean;
  itemsLength: number;
  error: { isError: boolean; errorMessage: string | null };
  hasMoreItems: boolean;
  targetRef: React.RefObject<HTMLDivElement>;
  fetchMoreData: () => void;
  refetch: (keepQuey: boolean) => void;
  totalPages: number;
}

const InfiniteStatusContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px; // Adjust the height as needed
  width: 100%;
`;

// Styling for message containers
const MessageContainer = styled.div`
  color: ${PRICE_CHECK_THEME_COLORS.DISCREET_TEXT}; // Primary text color: ;
  font-family: "HK Grotesk Bold";
  font-size: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InfiniteScrollStatusDisplay: React.FC<ScrollProps> = ({
  isLoading,
  itemsLength,
  error,
  hasMoreItems,
  totalPages,
  fetchMoreData,
  refetch,
  targetRef,
}) => {
  return (
    <InfiniteStatusContainer ref={targetRef}>
      {/* Error Handling */}
      {error?.isError === true  && (
        <MessageContainer>
          {error.errorMessage}
          <Button
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              padding: "1.1rem",
              fontSize: "1rem",
              marginBottom: "1rem",
              fontFamily: "HK Grotesk SemiBold",
              borderRadius: "10px",
            }}
            type="primary"
            icon={<RefreshCw size={"1em"} strokeWidth={"3px"} />}
            onClick={() => refetch(true)}
          >
            <span style={{ marginTop: "2px" }}>Försök igen</span>
          </Button>
        </MessageContainer>
      )}

      {/* Infinite scroll loader */}
      <div>{isLoading && <Spin size="large" />}</div>

      {/* No data message */}
      {!isLoading && itemsLength === 0 && (
        <MessageContainer>Ingen data tillgänglig</MessageContainer>
      )}

      {/* No more items to load */}
      {itemsLength > 0 && hasMoreItems === false && (
        <MessageContainer>Inga fler objekt att ladda</MessageContainer>
      )}

      {/* Fallback: Show "Load More" button if IntersectionObserver doesn't work */}
      {hasMoreItems && !isLoading && !("IntersectionObserver" in window) && (
        <Button
          onClick={fetchMoreData}
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            padding: "1.1rem",
            fontSize: "1rem",
            fontFamily: "HK Grotesk SemiBold",
            borderRadius: "10px",
          }}
          type="primary"
          icon={<ArrowBigDownDash size={"1em"} strokeWidth={"3px"} />}
        >
          <span style={{ marginTop: "2px" }}>Ladda fler</span>
        </Button>
      )}
    </InfiniteStatusContainer>
  );
};
