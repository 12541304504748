import React from 'react';
import styled from '@emotion/styled'
import {
  Typography,
} from "antd";

const SectionHeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin-bottom: 1.2rem;
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
`;

interface SectionHeaderProps {
  title: string;
  subText?: string;
  icon: React.ReactNode;
  inlineStyle?: React.CSSProperties;
  titleSize?: 1 | 2 | 3 | 4 | 5;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subText, icon, titleSize, inlineStyle, ...props }) => {
  return (
    <SectionHeaderWrapper style={inlineStyle} {...props}>
      {icon}
      <Typography.Title level={titleSize ?? 4} color="#626262">
        {title}
        {subText && (
          <>
            {' '}
            <span style={{color: "#848484", fontSize: "0.8em"}}>{subText}</span>
          </>
        )}
      </Typography.Title>
    </SectionHeaderWrapper>
  );
}
