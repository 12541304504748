import React from "react";
import { Drawer, Button, Space, Tooltip } from "antd";
import { Download, XCircle } from "lucide-react";
import styled from "@emotion/styled";
import usePriceCheckStore from "stores/priceCheckStore";
import { calculatePercentageDiff } from "utility/helper";
import { PRICE_CHECK_THEME_COLORS } from "utility/priceCheck/constants";
import { CSVLink } from "react-csv";
import { useLocaleStore } from "stores/localeStore";
import { enhanceProduct } from "interfaces/apiTypes";

const DrawerStyled = styled(Drawer)`
  .ant-drawer-title {
    font-size: 18px;
  }
  .ant-drawer-body {
    padding: 0 24px;
    /* @media (max-width: 800px) {
      padding: 0;
    } */
  }
`;

const TitleStyled = styled.span`
  font-family: "HK Grotesk Bold";
  color: black;
  font-size: 1.25rem;
  margin: auto 0;
`;

const ButtonStyled = styled(Button)`
  justify-content: center;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 1.1rem;
  font-size: 1rem;
  font-family: "HK Grotesk SemiBold";
  border-radius: 10px;
`;

const TableStyled = styled.div`
  width: 100%;
  background: white;

  .percentage-diff {
    font-weight: 500;
    font-size: 1rem;
    font-family: "HK Grotesk Bold";
    opacity: 0.9;
  }
  .comparison-lower {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.SUCCESS};
  }
  .comparison-higher {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.FAIL};
  }
  .main-price {
    opacity: 0.5;
    font-size: 0.9rem;
  }
  .comparison-price {
    opacity: 1;
    font-size: 1.1rem;
  }
`;

const TableBodyStyled = styled.div`
  /* border-top: 1px solid #e5e7eb; */
`;

const TableRowStyled = styled.div`
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #e5e7eb;
  column-gap: 0.5rem;
`;

const TableCellStyled = styled.div`
  flex: 1; /* Makes all cells take up equal space */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;

  &.remove-cell {
    max-width: 50px; /* Small fixed width for the remove button */
    justify-content: flex-end;
  }

  &.product-name-cell {
    justify-content: flex-start;
  }

  &.percentage-cell {
    max-width: 70px;
  }

  &.prices-info-container {
    /* display: flex;
    width: 100%; */
  }

  &.price-cell {
    justify-content: start;
  }
  .price-container {
    display: flex;
    flex-direction: column;
    font-family: "HK Grotesk SemiBold";
  }
`;

const RemoveButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  &:hover {
    color: #ef4444;
  }
`;

const MainItemStyled = styled.div`
  font-size: 1rem;
  font-family: "HK Grotesk SemiBold";
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ComparisonItemStyled = styled.div`
  color: #6b7280;
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

interface FilterDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const PriceCheckListDrawer: React.FC<FilterDrawerProps> = ({
  visible,
  onClose,
}) => {
  const onCloseEvent = () => {
    onClose();
  };
  const { currency } = useLocaleStore();
  const { selectedPriceHits: selectedItems, removeSelectedPriceHit: removeSelectedItem } = usePriceCheckStore();
  const { activeLanguage } = useLocaleStore();

  const csvData = [
    [
      "ArtNr",
      "Lev.ArtNr",
      "UtPrisExkl",
      "Leverantör",
    ],
    ...Object.values(selectedItems).map((selection) => {
      const comparisonPrice = selection?.comparisonItem?.price;
      const exVatComparisonPrice = comparisonPrice ? comparisonPrice / 1.25 : "N/A"; // Calculate ex-VAT price

      return [
        selection?.mainItem?.item?.itemNo ?? "",
        selection?.mainItem.item.supplierNo ?? "",
        exVatComparisonPrice,
        selection?.mainItem?.item.supplier
      ];
    }),
  ];

  return (
    <DrawerStyled
      title={<TitleStyled>Valda artiklar ({Object.keys(selectedItems).length})</TitleStyled>}
      width={600}
      onClose={onCloseEvent}
      open={visible}
      extra={
        <Space>
          <Tooltip
            color={"gray"}
            placement="topRight"
            title={"Exportera/ladda ner som csv"}
          >
            <CSVLink
              data={csvData}
              filename={`priser-${activeLanguage?.locale}.csv`}
              target="_blank"
            >
              <ButtonStyled
                size="middle"
                type="primary"
                icon={<Download size={"1em"} strokeWidth={"3px"} />}
                // onClick={() => {
                //   console.log("download csv");
                // }}
              >
                <span style={{ marginTop: "2px" }}>Exportera</span>
              </ButtonStyled>
            </CSVLink>
          </Tooltip>
        </Space>
      }
    >
      <TableStyled>
        <TableBodyStyled>
          {Object.entries(selectedItems).map(([itemId, selection]) => {
              // @ts-ignore
            const product = enhanceProduct(selection?.mainItem?.item?.product, activeLanguage?.id);
            const mainPrice = product.getPrice() ?? undefined;
            const mainItemName = product.getName() ?? "";
            const comparisonPrice = selection?.comparisonItem?.price;
            const pricesAreValid = mainPrice && comparisonPrice;
            const trackedDifferencePercentage = pricesAreValid
              ? calculatePercentageDiff(mainPrice, comparisonPrice)
              : null;
            const isTrackedPriceLower = pricesAreValid
              ? comparisonPrice < mainPrice
              : false;

            return (
              <TableRowStyled key={itemId}>
                <TableCellStyled className="product-name-cell">
                  <MainItemStyled
                    // title={`${selection?.mainItem?.item?.itemNo ?? ""} ${
                    //   mainItemName ?? ""
                    // }`}
                  >
                    {selection?.mainItem?.item?.itemNo}
                  </MainItemStyled>
                </TableCellStyled>
                <TableCellStyled className="comparison-name-cell">
                  <ComparisonItemStyled
                    title={selection?.comparisonItem?.companyName ?? ""}
                  >
                    {selection?.comparisonItem?.companyName}
                  </ComparisonItemStyled>
                </TableCellStyled>
                <TableCellStyled className="price-cell">
                  <div className="price-container">
                    <span className="main-price">
                      {mainPrice ? `${mainPrice}` : "Inget orginal pris"}{" "}
                      <span className="currency"> {currency}</span>
                    </span>
                    <span className="comparison-price">
                      {selection?.comparisonItem?.price}{" "}
                      <span className="currency"> {currency}</span>
                    </span>
                  </div>
                </TableCellStyled>
                <TableCellStyled className="percentage-cell">
                  <div className="percentage-container">
                    <span
                      className={`percentage-diff default-cursor ${
                        isTrackedPriceLower
                          ? "comparison-higher"
                          : "comparison-lower"
                      }`}
                    >
                      {
                        trackedDifferencePercentage ? `${trackedDifferencePercentage}%` : ""
                      }
                    </span>
                  </div>
                </TableCellStyled>

                <TableCellStyled className="remove-cell">
                  <RemoveButtonStyled
                    onClick={() => removeSelectedItem(Number(itemId))}
                  >
                    <XCircle />
                  </RemoveButtonStyled>
                </TableCellStyled>
              </TableRowStyled>
            );
          })}
        </TableBodyStyled>
      </TableStyled>
    </DrawerStyled>
  );
};

export default PriceCheckListDrawer;
