import { WorkTask, WorkTaskType } from 'interfaces/apiTypes';
import MagicSelect from './magicSelect'; // Import MagicSelect
import { BaseItem, FilterOperatorEnum } from 'interfaces/baseTypes';
import useWorkTaskOpenApi from 'apiClients/useWorkTaskOpenApiClient';
import { convertFiltersToString } from 'utility/helper';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query'; // Import useQuery from react-query

const formatWorkTaskOption = (task: BaseItem<WorkTask>) => ({
    value: task.id,
    label: task.item.name,
});

const WorkTaskSelect = ({defaultWorkTaskId, onSelectedIdChange, workTaskType}: {defaultWorkTaskId?: number, onSelectedIdChange: (value?: number) => void, workTaskType?: WorkTaskType}) => {
    const [selectedId, setSelectedId] = useState<number | undefined>(defaultWorkTaskId ?? undefined);

    useEffect(() => {
        setSelectedId(defaultWorkTaskId ?? undefined);
    }, [defaultWorkTaskId]);

    const { getWorkTasks } = useWorkTaskOpenApi();

    const handleSelect = (value?: number) => {
        setSelectedId(value ?? undefined);
        onSelectedIdChange(value ?? undefined);
    };

    const { data: workTasks, refetch: refetchWorkTasks } = useQuery(
        ['workTasksSelect', workTaskType],
        () => getWorkTasks({ filters: convertFiltersToString([{ fieldName: "type", operator: FilterOperatorEnum.EQUALS, value: workTaskType?.toString() ?? '' }]) }),
        { enabled: !!workTaskType }
    );

    const onSearch = async (value: string) => {
        const filters = [{ fieldName: "name", operator: FilterOperatorEnum.CONTAINS , value: value }];
        if (workTaskType) {
            filters.push({ fieldName: "type", operator: FilterOperatorEnum.EQUALS, value: workTaskType.toString() });
        }
        const response = await getWorkTasks({ filters: convertFiltersToString(filters) });
        return response?.items ?? [];
    };

    const fetchMissingOptions = async (value: number) => {
        const filters = [{ fieldName: "id", operator: FilterOperatorEnum.EQUALS, value: value.toString() }];
        if (workTaskType) {
            filters.push({ fieldName: "type", operator: FilterOperatorEnum.EQUALS, value: workTaskType.toString() });
        }
        const response = await getWorkTasks({filters: convertFiltersToString(filters)});
        return response?.items ?? [];
    };

    return (
        <MagicSelect
            value={selectedId}
            Options={workTasks?.items ?? []}
            onSearch={onSearch}
            formatOption={formatWorkTaskOption}
            fetchMissingOptions={fetchMissingOptions}
            placeholder="Välj arbetslista"
            onSelect={handleSelect}
            widthRem={15}
        />
    );
};

export default WorkTaskSelect;
