interface ResponseWrapper<T> {
  value: BaseItem<T>;
  metaData: {
    get: MetaDataItem[];
    create: MetaDataItem[];
    update: MetaDataItem[];
  };
}

export interface BaseItem<T> {
  id: number;
  createdAt: string;
  modifiedAt: string;
  item: T;
}

interface MetadataResponseWrapper {
  metaData: {
    get: MetaDataItem[];
    create: MetaDataItem[];
    update: MetaDataItem[];
  };
}

interface MetaDataItem {
  fullName: string;
  propertyPaths: string[];
  fields: Field[];
}

interface Field {
  name: string;
  fieldType: number;
  isEntity: boolean;
  isEnumeration: boolean;
  isEnum: boolean;
  enumInfo: EnumInfo[];
}

interface EnumInfo {
  initValue: number;
  enumValue: string;
}

enum SortOrderEnum {
  ASC = 0,
  DESC = 1,
}

interface GetManyRequestOptions {
  page?: number;
  pageSize?: number;
  order?: SortOrderEnum;
  orderByField?: string;
  filters?: string;
}

interface ErrorResponse {
  message: string;
}

enum FilterOperatorEnum {
  EQUALS = "equals",
  NOT_EQUALS = "notequals",
  CONTAINS = "contains",
  STARTS_WITH = "startswith",
  ENDS_WITH = "endswith",
  LESS_THAN = "lessthan",
  GREATER_THAN = "greaterthan",
  LESS_THAN_OR_EQUAL = "lessthanorequal",
  GREATER_THAN_OR_EQUAL = "greaterthanorequal"
}

export interface FilterItem {
  fieldName: string;
  value: string;
  operator: FilterOperatorEnum;
}

declare global {
  interface Array<T> {
    findFilterIndexByFieldName(fieldName: string): number;
    updateFilterValueByFieldName(fieldName: string, value: string): void;
    addFilterItem(fieldName: string, value: string, operator: FilterOperatorEnum): FilterItem;
    filterItemExists(fieldName: string): boolean;
  }
}

Array.prototype.findFilterIndexByFieldName = function (fieldName: string): number {
  return this.findIndex((filter: FilterItem) => filter.fieldName === fieldName);
};

Array.prototype.updateFilterValueByFieldName = function (fieldName: string, value: string): void {
  const index = this.findFilterIndexByFieldName(fieldName);
  if (index !== -1) {
    this[index].value = value;
  }
};

Array.prototype.addFilterItem = function (fieldName: string, value: string, operator: FilterOperatorEnum): FilterItem {
  const newFilterItem: FilterItem = {
    fieldName: fieldName,
    value: value,
    operator: operator
  };
  this.push(newFilterItem);
  return newFilterItem;
};

Array.prototype.filterItemExists = function (fieldName: string): boolean {
  return this.findFilterIndexByFieldName(fieldName) !== -1;
};

export type { ResponseWrapper, MetaDataItem, Field, EnumInfo, GetManyRequestOptions, MetadataResponseWrapper, ErrorResponse };
export { SortOrderEnum, FilterOperatorEnum };
