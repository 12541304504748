import styled from "@emotion/styled";
import {
  CARD_ENTITY_MAX_HEIGHT,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_GAP_SPACE,
  DEFAULT_BOX_SHADOW,
  MAIN_ENTITY_CONTENT_WIDTH,
  MAIN_ENTITY_TOP_AREA_HEIGHT,
  MAIN_PRICE_WIDGET_WIDTH,
  MAX_HEIGHT_PRODUCT_IMAGE,
  MAX_HEIGHT_PRODUCT_INFORMATION,
  PRICE_CHECK_THEME_COLORS,
  PRICE_WIDGET_MAX_HEIGHT,
  PRICE_WIDGET_WIDTH,
} from "utility/priceCheck/constants";

export const PriceCheckWrapper = styled("div")`
  // A short transition to make first content loading smoother
  transition: opacity 0.08s ease-out;
  &.data-loading-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; // Prevent interaction with the hidden content
  }
  &.data-no-loading-visible {
    visibility: visible;
    opacity: 1;
  }
  .ant-card {
    overflow: hidden;
  }
  .ant-card > .ant-card-body {
    padding: 0 12px;
    position: relative;
  }

  .line-clamp-text {
    word-break: break-all; /* Break text at any character */
    font-weight: 500;
    overflow: hidden;
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .icon-button-discreet {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    min-width: 0;
    min-height: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    opacity: 1;
    :hover {
      opacity: 0.8;
    }
  }
`;

// Card design container
export const CardEntityContent = styled.div`
  display: grid;
  grid-template-columns: ${`${MAIN_ENTITY_CONTENT_WIDTH}px`} 1fr; /* MainEntityWrapper: 300px, CompareEntitiesWrapper: dynamic (1fr) */
  width: 100%;
  height: ${`${CARD_ENTITY_MAX_HEIGHT}px`};
  position: relative;

  &::before {
    content: "";
    position: absolute; /* Keep the gradient fixed relative to the viewport */
    top: 0;
    right: 0; /* Keep it aligned to the right of the wrapper */

    height: 100%;
    width: 0.5rem; /* Width of the gradient area */
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 255),
      rgba(255, 255, 255, 0)
    );
    pointer-events: none; /* Allow interaction with scrollable content */
    z-index: 1; /* Ensure the gradient stays on top of the content */
  }
  .currency {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: "HK Grotesk SemiBold";
    opacity: 0.5;
  }

  .main-entity-title {
    margin-top: 4px;
    font-family: "HK Grotesk SemiBold";
    font-weight: 600;
    font-size: 1rem;
    color: ${PRICE_CHECK_THEME_COLORS.PRIMARY_TEXT};
  }

  .compare-entity-title {
    font-size: 1rem !important;
    font-family: "HK Grotesk SemiBold";
    color: ${PRICE_CHECK_THEME_COLORS.DISCREET_TEXT};
    padding-bottom: 0.5rem;
  }

  .comparison-price {
    font-size: 1rem;
    font-family: "HK Grotesk Bold";
    color: ${PRICE_CHECK_THEME_COLORS.PRIMARY_TEXT};
  }

  .date {
    font-family: "HK Grotesk Semibold";
    font-size: 0.8rem;
    color: ${PRICE_CHECK_THEME_COLORS.DISCREET_TEXT};
    opacity: 0.85;
  }
  .prop-name {
    font-weight: 500;
    color: ${PRICE_CHECK_THEME_COLORS.DISCREET_TEXT};
    font-family: "HK Grotesk SemiBold";
  }

  .icon-button {
    color: ${PRICE_CHECK_THEME_COLORS.PRIMARY};
    cursor: pointer;
    opacity: 1;
    :hover {
      opacity: 0.6;
    }
  }

  .comparison-equal {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.DEFAULT};
  }
  .comparison-lower {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.SUCCESS};
  }
  .comparison-higher {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.FAIL};
  }
  .default-state {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.DEFAULT};
    opacity: 0.9;
  }
  .success-state {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.SUCCESS};
  }
  .fail-state {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.FAIL};
  }
`;

export const MainEntityWrapper = styled.div`
  border-right: 1px solid ${PRICE_CHECK_THEME_COLORS.DISCREET_BORDER};
  /* background-color: lime; */
  height: 100%; /* Fill the height of the parent container */
  max-height: ${`${CARD_ENTITY_MAX_HEIGHT}px`};
  max-width: ${`${MAIN_ENTITY_CONTENT_WIDTH}px`};
  position: relative;

  &::before {
    content: "";
    position: absolute; /* Keep the gradient fixed relative to the viewport */
    top: 0;
    right: ${`-${DEFAULT_GAP_SPACE + 1}px`};
    height: 100%;
    width: ${`${DEFAULT_GAP_SPACE}px`};
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    pointer-events: none; /* Allow interaction with scrollable content */
    z-index: 1; /* Ensure the gradient stays on top of the content */
  }

  .main-entity-content {
    display: grid;
    grid-template-columns: 1fr ${`${MAIN_PRICE_WIDGET_WIDTH}px`};
    height: 100%;
  }

  .main-entity-information-wrapper {
    max-height: ${`${CARD_ENTITY_MAX_HEIGHT}px`};
    display: flex;
    box-sizing: border-box;
    /* background-color: green; */
  }
  .main-entity-price-widget-wrapper {
    max-height: ${`${CARD_ENTITY_MAX_HEIGHT}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* background-color: purple; */
  }
`;

export const MainEntityInformation = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: ${`${MAIN_ENTITY_TOP_AREA_HEIGHT}px`} 1fr;
  grid-template-columns: 1fr;

  // Top area content
  .main-entity-top-area-content {
    display: flex;
    align-items: center;
    padding: ${`${DEFAULT_GAP_SPACE}px`} 0 0 ${`${DEFAULT_GAP_SPACE}px`};
    /* background-color: #7a889d; */
    column-gap: ${`10px`};
  }
  // Bottom area content
  .main-entity-grid-bottom-area-content {
    display: grid;
    grid-template-columns: 100px 1fr;
  }

  .main-entity-grid-area-content-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-image {
    /* background-color: #5a83c2; */
    border-radius: ${`${DEFAULT_BORDER_RADIUS}px`};
    overflow: hidden; /* Ensures that the image corners are hidden */
    max-width: 100%;
    max-height: ${`${MAX_HEIGHT_PRODUCT_IMAGE}px`};
    margin: 0 ${`${DEFAULT_GAP_SPACE}px`} 0 ${`${DEFAULT_GAP_SPACE}px`};
  }

  .main-entity-grid-area-content-information {
    /* background-color: #138266; */
    height: ${`${MAX_HEIGHT_PRODUCT_INFORMATION}px`};
  }
`;

export const MainEntityPropsList = styled("ul")`
  list-style-type: none;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1em;
  padding-top: ${`${DEFAULT_GAP_SPACE + 5}px`};
  color: ${PRICE_CHECK_THEME_COLORS.DISCREET_TEXT};
  li {
    font-size: 0.85em;
  }
`;

export const CompareEntitiesWrapper = styled.div`
  position: relative; /* Make the wrapper a positioning context */
  display: flex;
  box-sizing: border-box;
  overflow-x: auto;
  /* Ensure the gradient is on the left side, but fixed in the container */
  .compare-entities-content {
    padding: 0 ${`${DEFAULT_GAP_SPACE}px`} 0 ${`${DEFAULT_GAP_SPACE}px`};
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    column-gap: ${`${DEFAULT_GAP_SPACE / 2}px`};
    flex-shrink: 0; /* Prevent shrinking */
    position: relative; /* Ensure the content stays inside the container */
  }
  .empty-compare-entities-content {
    display: flex;
    padding-left: 1rem;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: "HK Grotesk Bold";
    color: #777777;
    opacity: 0.4;
  }
`;

export const PriceWidget = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: ${`${DEFAULT_BORDER_RADIUS}px`};
  /* height: 90%; */
  height: 100%;
  max-height: ${`${PRICE_WIDGET_MAX_HEIGHT - DEFAULT_GAP_SPACE * 2}px`};
  padding: ${`${DEFAULT_GAP_SPACE / 2}px`};
  background-color: #f5f7f9;
  width: ${`${PRICE_WIDGET_WIDTH}px`};
  :hover {
    background-color: #f7fafc;
    border: 2px solid #d4d4d4;
  }
  .price-widget-content {
    cursor: pointer;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space between the first items and the last one */
  }
  .price-widget-list {
    /* cursor: pointer; */
    list-style-type: none;
    width: 100%;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    li {
      font-size: 0.8em;
    }
  }
  .default-cursor {
    cursor: default;
  }
  .pointer-cursor {
    cursor: pointer;
  }
  .auto-cursor {
    cursor: auto;
  }

  border: 2px solid transparent;
  &.price-widget-selected {
    background-color: white;
    /* border: 2px solid ${PRICE_CHECK_THEME_COLORS.STATE.SUCCESS}; */
    border: 2px solid ${PRICE_CHECK_THEME_COLORS.PRIMARY};
    box-shadow: ${DEFAULT_BOX_SHADOW};
    transition: 0.1s;
  }

  &.main-widget {
    box-shadow: ${DEFAULT_BOX_SHADOW};
  }

  // Enhance names and colors, reused on multiple places
  .percentage-diff {
    font-weight: 500;
    font-size: 1.2rem;
    font-family: "HK Grotesk Bold";
    /* opacity: 0.9; */
  }

  .default-link-state {
    color: ${PRICE_CHECK_THEME_COLORS.LINK};
    transition: transform 0.2s ease-in-out;
    display: inline-block;
    position: relative; /* Keeps the element's position intact */
  }

  .default-link-state:hover {
    transform: scale(1.2); /* Enlarges the icon on hover */
    transform-origin: center;
    z-index: 1; /* Ensures the enlarged icon stays on top of other elements */
  }

  .fail-link-state {
    color: ${PRICE_CHECK_THEME_COLORS.DISABLED};
    opacity: 0.5;
  }

  .icon-container {
    width: 1.1rem; /* Fixed width and height to prevent layout shifts */
    height: 1.1rem;
    display: inline-block; /* Keep the icon inline */
    overflow: hidden; /* Prevent enlarged icon from affecting other elements */
    position: relative; /* Keeps hover effects contained */
  }

  .product-name-wrapper {
    margin-top: 3px;
    font-size: 0.7rem;
    font-family: "HK Grotesk SemiBold";
    color: ${PRICE_CHECK_THEME_COLORS.PRIMARY_TEXT};
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: 160px;
  }
`;

export const PriceMainWidget = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: ${`${DEFAULT_BORDER_RADIUS}px`};
  /* height: 90%; */
  height: 100%;
  max-height: ${`${PRICE_WIDGET_MAX_HEIGHT - DEFAULT_GAP_SPACE * 2}px`};
  padding: ${`${DEFAULT_GAP_SPACE / 2}px`};
  background-color: #f5f7f9;
  width: ${`${PRICE_WIDGET_WIDTH}px`};

  &.main-widget {
    box-shadow: ${DEFAULT_BOX_SHADOW};
  }

  .main-widget-content {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .main-widget-list {
    /* cursor: pointer; */
    list-style-type: none;
    width: 100%;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    li {
      font-size: 0.8em;
    }
  }
  border: 2px solid transparent;

  // Enhance names and colors, reused on multiple places
  .main-percentage-diff {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.FAIL};
    font-weight: 500;
    font-size: 1rem;
    font-family: "HK Grotesk Bold";
    opacity: 0.9;
  }

  .default-link-state {
    color: ${PRICE_CHECK_THEME_COLORS.LINK};
    transition: transform 0.2s ease-in-out;
    display: inline-block;
    position: relative; /* Keeps the element's position intact */
  }

  .default-link-state:hover {
    transform: scale(1.2); /* Enlarges the icon on hover */
    transform-origin: center;
    z-index: 1; /* Ensures the enlarged icon stays on top of other elements */
  }

  .fail-link-state {
    color: ${PRICE_CHECK_THEME_COLORS.DISABLED};
    opacity: 0.5;
  }

  .icon-container {
    display: inline-block; /* Keep the icon inline */
    overflow: hidden; /* Prevent enlarged icon from affecting other elements */
    position: relative; /* Keeps hover effects contained */
  }
`;