import React from "react";
import { SectionHeader } from "components/layout/header/sectionHeader";
import { DividerLine } from "components/separators/dividerLine";

interface WorkTaskSectionHeaderProps {
  title?: string;
  subText?: string;
  icon?: React.ReactNode;
  titleSize?: 1 | 2 | 3 | 4 | 5;
  renderActionElement?: (props: {
    title: string;
    subText: string;
  }) => React.ReactNode;
  showSeparatorLine?: boolean;
}

const WorkTaskSectionHeader: React.FC<WorkTaskSectionHeaderProps> = ({
  title = "",
  subText = "",
  icon = null,
  titleSize,
  renderActionElement,
  showSeparatorLine = false,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          backgroundColor: "white",
          paddingTop: "1rem",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: "0.5rem",
          flexWrap: "wrap",
        }}
      >
        <SectionHeader
          titleSize={titleSize ?? 4}
          inlineStyle={{ display: "inline-flex", marginBottom: "0" }}
          title={title}
          subText={subText}
          icon={icon}
        />
        <div style={{ display: "inline-flex", gap: "0.5rem" }}>
        {renderActionElement && renderActionElement({ title, subText })}
        </div>
        {showSeparatorLine && <DividerLine style={{ margin: "0" }} />}
      </div>
    </div>
  );
};

export default WorkTaskSectionHeader;
