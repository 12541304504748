import { useEffect } from "react";
import usePriceTrackerOpenApi from "apiClients/usePriceTrackerOpenApiClient";
import usePriceCheckStore from "stores/priceCheckStore";
import { useNotification } from "@refinedev/core";

const SUCCESS_MESSAGE = "Uppdatering av valda poster har startas";
const ERROR_MESSAGE = "Misslyckades med att starta uppdatering av valda poster";

const useRegeneratePriceComparisons = () => {
  const { open } = useNotification();
  const { selectedUpdateItems, setSelectedUpdateItems, setGlobalLoading } =
    usePriceCheckStore();
  const { priceTrackerBatchSynchronize, errorMessage, loading } =
    usePriceTrackerOpenApi();

  const selectedUpdateItemsCount = Object.keys(
    selectedUpdateItems || {}
  ).length;

  useEffect(() => {
    setGlobalLoading(loading, "Uppdaterar prisjämförelser");
  }, [loading]);

  const updatePriceComparisonItems = async () => {
    const priceTrackerIds = Object.values(selectedUpdateItems).map(
      (item) => item.productId
    );

    await priceTrackerBatchSynchronize(priceTrackerIds);

    if (errorMessage) {
      open?.({
        message: ERROR_MESSAGE,
        type: "error",
      });
    }

    if (!loading && !errorMessage) {
      open?.({
        message: SUCCESS_MESSAGE,
        type: "success",
      })
      setSelectedUpdateItems({});
    }
  };

  return {
    selectedUpdateItemsCount,
    loading, // Return loading state from the API hook
    successMessage: !errorMessage ? SUCCESS_MESSAGE : null, // Show success message if there's no error
    errorMessage, // Return the error message directly from the API hook
    updatePriceComparisonItems, // The function to trigger the update
  };
};

export default useRegeneratePriceComparisons;