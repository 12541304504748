import usePriceTrackerOpenApi from "apiClients/usePriceTrackerOpenApiClient";
import { FilterItem, SortOrderEnum } from "interfaces/baseTypes";
import { useEffect, useRef, useState } from "react";
import { useLocaleStore } from "stores/localeStore";
import usePriceCheckStore from "stores/priceCheckStore";
import { convertFiltersToString, convertFiltersToStringIncludeLanguage, ensureLanguageFilterItem } from "utility/helper";


const usePriceCheckInfiniteScroll = (pageSize = 10) => {
  // Zustand store
  const {
    resetState,
    addDataToDataSource,
    currentQuery,
    dataSourceIsLoading,
    setDataSourceIsLoading,
    dataSourceError,
    setDataSourceError,
  } = usePriceCheckStore(); 

  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const { activeLanguage } = useLocaleStore();

  const { getPriceTrackers } = usePriceTrackerOpenApi();
  const prevQueryRef = useRef<FilterItem[]>(currentQuery);
  const currentPageRef = useRef<number>(0);

  useEffect(() => {
    if (resetFlag) {
      initialFetch();
      setResetFlag(false);
    }
  }, [resetFlag]);

  useEffect(() => {
    resetAllStates(true);
  }, [activeLanguage?.id]);

  const initialFetch = async () => {
    await fetchPriceTrackers();
  };

  const fetchPriceTrackers = async () => {
    if (dataSourceIsLoading || !hasMoreItems) return;
    setDataSourceIsLoading(true);
    setDataSourceError({ isError: false, errorMessage: "", error: null });
    try {
      const request = {
        page: currentPageRef.current,
        pageSize: pageSize,
        filters: convertFiltersToString(ensureLanguageFilterItem(currentQuery, "languageId", activeLanguage)),
        orderByField: "createdAt",
        order: SortOrderEnum.DESC
      }
      const response = await getPriceTrackers(request);

      if (response) {
        const items = response?.items || [];
        addDataToDataSource(items);
        setTotalPages(Math.ceil(response.totalCount / pageSize));
        setTotalCount(response.totalCount);
        setHasMoreItems(response.hasNextPage);
        currentPageRef.current += 1; // Increment the ref value directly
      }else{
        setDataSourceError({
          isError: true,
          errorMessage: "Listan är tom"
        });
      }
    } catch (e: any) {
      setDataSourceError({
        isError: true,
        errorMessage: e.message || "Error fetching data.",
        error: e,
      });
    } finally {
      setDataSourceIsLoading(false);
    }
  };

  // Refetch function
  const refetch = (keepQuery: boolean) => {
    resetAllStates(keepQuery);
  };

  // Function to reset state and refs
  const resetAllStates = (keepQuery: boolean) => {
    resetState({ keepCurrentQuery: keepQuery });
    setHasMoreItems(true);
    setTotalPages(0);
    setTotalCount(0);
    setResetFlag(true);
    currentPageRef.current = 0; // Reset the ref directly
    prevQueryRef.current = [];
  };

  // Reset states when query changes
  useEffect(() => {
    // If the query is empty or undefined, clear everything and stop fetching
    if (currentQuery?.length === 0) {
      resetAllStates(true);
      return;
    }
  
    // Check if query has changed and proceed with fetching data if valid
    if (prevQueryRef.current?.length !== currentQuery?.length) {
      prevQueryRef.current = currentQuery;
      resetAllStates(true);
      fetchPriceTrackers();
    }
  }, [currentQuery]);

  // Setup IntersectionObserver for infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMoreItems && !dataSourceIsLoading && currentQuery && !dataSourceError.isError) {
          fetchPriceTrackers();
        }
      },
      {
        root: null, // Use the viewport as the root container (null means the whole screen)
        rootMargin: "100px", // Start loading when the element is 100px before it enters the viewport
        threshold: 0.01, // Trigger when 1% of the observed element is visible
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.disconnect(); // Cleanup observer
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [hasMoreItems, dataSourceIsLoading, currentQuery]);

  return {
    infiniteItemsIsLoading: dataSourceIsLoading,
    infiniteItemsLength: totalCount,
    infiniteScrollError: dataSourceError,
    hasMoreItems,
    totalPages,
    fetchMoreData: fetchPriceTrackers,
    refetch,
    targetRef,
  };
};

export default usePriceCheckInfiniteScroll;

