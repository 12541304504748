import React, { useState } from "react";
import { useList } from "@refinedev/core";
import { BellOutlined } from "@ant-design/icons";
import { Bell, List } from "lucide-react";
import { Badge, Button, Divider, Popover, Space, Spin } from "antd";

import { JOB_RES_ID } from "utility/constants";
import { IJob } from "interfaces";
import { Text } from "components/common/text";
import { NotificationMessage } from "./NotificationMessage";
import { DateText } from "components/common/dateText";
import NotificationImage from "./NotificationImage";

export const Notifications: React.FC = () => {
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useList<IJob>({
    resource: JOB_RES_ID,
    pagination: {
      pageSize: 5,
    },
    sorters: [{ field: "createdAt", order: "desc" }],
  });

  const jobs = data?.data ?? [];

  const content = (
    <Space direction="vertical" split={<Divider style={{ margin: 0 }} />}>
      {jobs.map((job) => {
        return (
          <Space key={job.id}>
            <NotificationImage type={job?.type}></NotificationImage>
            <Space direction="vertical" size={0}>
              <NotificationMessage job={job} />
              <Text size="xs" type="secondary">
                <DateText date={job?.createdAt}></DateText>
              </Text>
            </Space>
          </Space>
        );
      })}
    </Space>
  );

  const loadingContent = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 20,
      }}
    >
      <Spin />
    </div>
  );

  return (
    <Popover
      placement="bottomRight"
      content={isLoading ? loadingContent : content}
      trigger="click"
      onOpenChange={(newOpen) => setOpen(newOpen)}
      overlayStyle={{ width: 400 }}
    >
      <Badge dot={jobs?.find((j) => !j.hasFinished) != null}>
        <Button
          shape="circle"
          icon={<Bell size={"1rem"} className="anticon" />}
          style={{ border: 0, alignItems: "center" }}
        />
      </Badge>
    </Popover>
  );
};
