// src/components/listViews/simpleListView.tsx

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  VisibilityState,
  getSortedRowModel,
  SortingState,
  Row,
  Table,
  RowSelectionState,
} from '@tanstack/react-table';
import { ResponseWrapperMany } from 'interfaces/apiTypes';
import styles from './simpleListView.module.css';
import { BaseItem, FilterItem, SortOrderEnum } from 'interfaces/baseTypes';
import { Button, Checkbox, Drawer, Pagination, Spin } from 'antd';
import { ArrowUpDown, ArrowUp, ArrowDown, Columns2 } from 'lucide-react';
import { convertFiltersToString } from 'utility/helper';

export const DEFAULT_LIST_COL_HEIGHT_PX = 48;

export interface ColumnConfig<T> {
  header: string;
  accessorKey: string;
  cell: (props: { row: { original: T } }) => JSX.Element;
  size?: number;
  sort?: boolean;
  visible?: boolean;
}

interface SimpleListViewProps<T> {
    columns: ColumnConfig<BaseItem<T>>[];
    fetchItems: (page: number, pageSize: number, order: SortOrderEnum, orderByField: string, filters?: string) => Promise<ResponseWrapperMany<BaseItem<T>> | undefined>;
    queryKey: string;
    equalColumnWidths?: boolean;
    resizableColumns?: boolean;
    onSelectionChange?: (selectedItems: BaseItem<T>[]) => void;
    selectable?: boolean;
    onRowClick?: (item: BaseItem<T>) => void;
    renderHeader?: (totalCount: number) => JSX.Element;
    filterItems?: FilterItem[];
    onCountChange?: (count: number) => void; // Add this line
    listLoadingHeightPx?: number;
}

export const SimpleListView = <T extends object>({
    columns,
    fetchItems,
    queryKey,
    equalColumnWidths = false,
    resizableColumns = false,
    onSelectionChange,
    selectable = false,
    onRowClick,
    renderHeader,
    filterItems,
    onCountChange,
    listLoadingHeightPx = DEFAULT_LIST_COL_HEIGHT_PX * 2,
}: SimpleListViewProps<T>) => {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(10);
    const [sorting, setSorting] = useState<SortingState>([]);

    const [selectedItems, setSelectedItems] = useState<BaseItem<T>[]>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [filterString, setFilterString] = useState<string>(''); 
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null); // Add this line

    useEffect(() => {
        const newFilterString = convertFiltersToString(filterItems || []);

        setFilterString(newFilterString);
    }, [filterItems]); 

    useEffect(() => {
        const savedVisibility = localStorage.getItem(`${queryKey}-columnVisibility`);
        if (savedVisibility) {
            setColumnVisibility(JSON.parse(savedVisibility));
        } else {
            // Set default visibility based on column's visible property
            const defaultVisibility: VisibilityState = {};
            columns.forEach(column => {
                defaultVisibility[column.accessorKey] = column.visible !== false; // Default to true if not set
            });
            setColumnVisibility(defaultVisibility);
        }
    }, [queryKey, columns]);

    useEffect(() => {
        if (columnVisibility && Object.keys(columnVisibility).length > 0 && queryKey) {
            localStorage.setItem(`${queryKey}-columnVisibility`, JSON.stringify(columnVisibility));
        }
    }, [columnVisibility, queryKey]);

    const { data, isLoading, error } = useQuery<ResponseWrapperMany<BaseItem<T>>, Error>({
        queryKey: [queryKey, currentPage, pageSize, sorting, filterString],
        queryFn: async () => {
            const result = await fetchItems(currentPage, pageSize, sorting.length > 0 ? sorting[0].desc ? SortOrderEnum.DESC : SortOrderEnum.ASC : SortOrderEnum.ASC,  sorting.length > 0 ? sorting[0].id : "id", filterString);
            if (!result) {
                throw new Error('No data returned');
            }
            setTotalCount(result.totalCount);
            
            // Call onCountChange if provided
            if (onCountChange) {
                onCountChange(result.totalCount);
            }

            //set selected rows based on if data is in selectedItems
            const newRowSelection = {} as RowSelectionState;
            result.items.forEach((item, index) => {
                newRowSelection[index] = selectedItems.some(selectedItem => selectedItem.id === item.id);
            });
            setRowSelection(newRowSelection);
            return result;
        }
    });

    const handleRowSelectionChange = useCallback((newSelection: Record<string, boolean>) => {
        setRowSelection(newSelection);
        const updatedSelectedItems = [...selectedItems];

        Object.entries(newSelection).forEach(([index, isSelected]) => {
            const item = data?.items[Number(index)];
            if (isSelected && item && !updatedSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
                updatedSelectedItems.push(item);
            } else if (!isSelected) {
                const itemIndex = updatedSelectedItems.findIndex(selectedItem => selectedItem.id === item?.id);
                if (itemIndex !== -1) {
                    updatedSelectedItems.splice(itemIndex, 1);
                }
            }
        });

        setSelectedItems(updatedSelectedItems || []);

        if (onSelectionChange) {
            onSelectionChange(updatedSelectedItems || []);
        }
    }, [data, onSelectionChange]);

    const selectColumn = useMemo(() => ({
        id: 'checkbox',
        header: ({ table }: { table: Table<any> }) => (
            <Checkbox
                checked={Object.keys(rowSelection).length > 0 && Object.values(rowSelection).every(Boolean)}
                indeterminate={Object.keys(rowSelection).length > 0 && Object.values(rowSelection).some(Boolean) && !Object.values(rowSelection).every(Boolean)}
                onChange={(e) => {
                    const newSelection: Record<number, boolean> = {};
                    data?.items.forEach((_, index) => {
                        newSelection[index] = e.target.checked;
                    });
                    handleRowSelectionChange(newSelection);
                }}
            />
        ),
        cell: ({ row }: { row: Row<any> }) => (
            <Checkbox
                checked={rowSelection[row.index]}
                onChange={(e) => {
                    e.stopPropagation(); // Prevent row click event
                    handleRowSelectionChange({
                        ...rowSelection,
                        [row.index]: e.target.checked,
                    });
                }}
            />
        ),
        enableSorting: false,
        enableResizing: false,
        size: 40,
    }), [data, rowSelection, handleRowSelectionChange]);

    const tableColumns = selectable
    ? [selectColumn, ...columns.map(column => ({
        ...column,
        size: column.size || 150,
        enableResizing: resizableColumns,
        enableSorting: column.sort || false,
      }))]
    : columns.map(column => ({
        ...column,
        size: column.size || 150,
        enableResizing: resizableColumns,
        enableSorting: column.sort || false,
    }));

    const table = useReactTable({
        data: data?.items || [],
        columns: tableColumns,
        state: {
            columnVisibility,
            sorting,
            rowSelection,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: resizableColumns,
        enableRowSelection: selectable,
    });

    const totalPages = Math.ceil(totalCount / (data?.items.length || 1)); // Calculate total pages

    const handlePageChange = (newPage: number, newPageSize: number) => {
        if(newPage >= 0 && newPage <= totalPages) {
            setCurrentPage(newPage - 1);
        }
        if(newPageSize !== pageSize) {
            setPageSize(newPageSize);
        }
    };

    const [isDragging, setIsDragging] = useState(false);
    const dragStartPos = useRef<{ x: number; y: number } | null>(null);

    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        dragStartPos.current = { x: e.clientX, y: e.clientY };
    }, []);

    const handleMouseMove = useCallback((e: React.MouseEvent) => {
        if (dragStartPos.current) {
            const dx = Math.abs(e.clientX - dragStartPos.current.x);
            const dy = Math.abs(e.clientY - dragStartPos.current.y);
            if (dx > 5 || dy > 5) {
                setIsDragging(true);
            }
        }
    }, []);

    const handleRowClick = useCallback((event: React.MouseEvent, target: EventTarget & HTMLTableRowElement) => {
        // Check if the click target is a checkbox or its label
        if (
            event.target instanceof HTMLInputElement && event.target.type === 'checkbox' ||
            event.target instanceof HTMLLabelElement
        ) {
            return; // Do nothing if checkbox or label was clicked
        }

        if (onRowClick) {
            const rowIndex = parseInt(target.dataset.rowIndex || '0', 10);
            const item = data?.items[rowIndex];
            if (item) {
                onRowClick(item);
                setSelectedRowIndex(rowIndex);
            }
        }
    }, [onRowClick, data]);

    const handleMouseUp = useCallback((e: React.MouseEvent) => {
        if (!isDragging) {
            handleRowClick(e, e.currentTarget as EventTarget & HTMLTableRowElement);
        }
        setIsDragging(false);
        dragStartPos.current = null;
    }, [isDragging, handleRowClick]);

    return (
      <div className={styles.container}>
        {error && <div className={styles.error}>{error.message}</div>}
        <div className={styles.tableControls}>
          {renderHeader && renderHeader(totalCount)}
        </div>
        <div className={styles.tableWrapper}>
          <table
            className={`${styles.table} ${
              equalColumnWidths ? styles.equalColumns : styles.defaultColumns
            }`}
          >
            <thead style={{ position: "relative" }}>
              <ColumnSelectorDrawerButton table={table} />
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className={styles.headerRow}>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={styles.headerCell}
                      style={{
                        width: header.getSize(),
                        position: "relative",
                        cursor: header.column.getCanSort()
                          ? "pointer"
                          : "default",
                      }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className={styles.headerContent}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {header.column.getCanSort() && (
                          <span className={styles.sortIcon}>
                            {header.column.getIsSorted() === "asc" ? (
                              <ArrowUp size={16} />
                            ) : header.column.getIsSorted() === "desc" ? (
                              <ArrowDown size={16} />
                            ) : (
                              <ArrowUpDown size={16} />
                            )}
                          </span>
                        )}
                      </div>
                      {resizableColumns && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`${styles.resizer} ${
                            header.column.getIsResizing()
                              ? styles.isResizing
                              : ""
                          }`}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <>
              {!isLoading && data && data.items.length > 0 ? (
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={`${styles.row} ${
                        selectedRowIndex === index ? styles.selectedRow : ""
                      }`}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      data-row-index={index}
                      style={{ cursor: onRowClick ? "pointer" : "default" }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={styles.cell}
                          style={{
                            width: cell.column.getSize(),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className={styles.noData} style={{ height:`${listLoadingHeightPx}px` }}>
                    <Spin
                      tip={"message"}
                      size="large"
                      spinning={true}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                </tbody>
              )}
            </>

            <tfoot>
              <tr>
                <td colSpan={table.getAllLeafColumns().length}>
                  <div className={styles.footer}>
                    <div>
                      Antal element: <strong>{totalCount}</strong>
                    </div>
                    <div>
                      <Pagination
                        current={currentPage + 1}
                        pageSize={pageSize}
                        total={totalCount}
                        onChange={handlePageChange}
                        showSizeChanger
                        pageSizeOptions={[10, 20, 50, 100]}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
};

const ColumnSelectorDrawerButton = <T extends object>({table}: {table: Table<BaseItem<T>>}) => {
    const [visible, setVisible] = useState(false);
    
    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    
    return (
      <>
        <Button onClick={showDrawer} className={styles.columnSelectorButton}>
          <Columns2 size={16} />
        </Button>
        <Drawer
          title="Välj aktiva kolumner"
          placement="right"
          onClose={onClose}
          open={visible}
        >
          <div style={{padding: '0rem 0.40rem'}}>
            {table.getAllLeafColumns().map((column) => (
              <label key={column.id} className={styles.columnToggle}>
                <Checkbox
                  checked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                />
                <span style={{ marginLeft: "0.5rem", fontSize: "16px" }}>
                  {column.id}
                </span>
              </label>
            ))}
          </div>
        </Drawer>
      </>
    );
}
