import { MenuProps } from "antd";
import { Dropdown } from "antd/lib";
import { RefreshCcw } from "lucide-react";
import useRegeneratePriceComparisons from "components/priceCheck/hooks/useRegeneratePriceComparisons";
import usePriceCheckStore from "stores/priceCheckStore";
const UPDATE_PRICE_COMPARISON_TITLE = "Omgenerera prisjämförelser";

export const useMultiGridMenuItems = () => {
  const items: MenuProps["items"] = [
    {
      label: UPDATE_PRICE_COMPARISON_TITLE,
      key: 1,
      icon: <RefreshCcw size={"1em"} />,
    },
  ];
  return { menuItems: items };
};

export const PriceCheckSelectedUpdateItemsActions = () => {
  const { setActiveModal, activeModal } = usePriceCheckStore();

  const {
    selectedUpdateItemsCount,
    updatePriceComparisonItems,
  } = useRegeneratePriceComparisons();
  const { menuItems } = useMultiGridMenuItems();

  const handleMainButtonClick = () => {
    updatePriceComparisonItems();
  };

  const onMultiButtonClick = (e: any) => {
    if (e.key == 1) {
      handleMainButtonClick();
    } else if (e.key == 2) {
      console.log("Other action");
    }
  };

  return (
    <>
      <Dropdown.Button
        menu={{ items: menuItems, onClick: onMultiButtonClick }}
        disabled={selectedUpdateItemsCount === 0}
        onClick={handleMainButtonClick}
      >
        {UPDATE_PRICE_COMPARISON_TITLE}
      </Dropdown.Button>
    </>
  );
};
