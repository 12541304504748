import { Modal } from "antd";
import { useNotification } from "@refinedev/core";
import usePriceCheckStore, { ActiveModalType } from "stores/priceCheckStore";
import { useState, useEffect } from "react";
import CreateNewPriceComparisons from "../sections/CreateNewPriceComparisons";

const PriceCheckModals = () => {
  const { setActiveModal, activeModal } = usePriceCheckStore();
  const { open } = useNotification();
  const [visibleModal, setVisibleModal] = useState<ActiveModalType | null>(null);

  const handleModalOk = () => {
    setActiveModal(null); // Close the modal on OK
  };

  const handleModalCancel = () => {
    setActiveModal(null); // Close the modal on Cancel
  };

  // Keep the modal content visible for a while after it’s closed, to preserve the leave animation
  useEffect(() => {
    if (activeModal) {
      setVisibleModal(activeModal);
    } else {
      // Add delay to preserve the leave animation before clearing the visible content
      const timer = setTimeout(() => setVisibleModal(null), 300); // 300ms for the animation
      return () => clearTimeout(timer); // Clear timeout on unmount
    }
  }, [activeModal]);

  // Configuration map to define modal properties dynamically
  const modalConfigMap = {
    "create-new-price-comparisons": {
      title: "Skapa nya prisjämförelser",
      width: 1200,
      footer: null,
      content: (
        <CreateNewPriceComparisons
          onSuccess={handleModalOk}
          openSuccessNotification={open}
          onCancel={handleModalCancel}
        />
      ),
    },
    "price-check-information": {
      title: "Information Priskoll",
      width: 800,
      footer: null,
      content: (
        <div>
          <p>Ingen information finns för tillfället.</p>
        </div>
      ),
    },
  };

  // Get the configuration for the current visible modal (not activeModal)
  const visibleModalConfig = modalConfigMap[visibleModal as keyof typeof modalConfigMap];

  return (
    <>
      {visibleModalConfig && (
        <Modal
          title={visibleModalConfig.title}
          open={!!activeModal} // Modal opens if activeModal is not null
          onCancel={handleModalCancel}
          forceRender={true}
          width={visibleModalConfig.width}
          footer={visibleModalConfig.footer}
          maskClosable={false}
          destroyOnClose={false} // Preserve modal content during transitions
        >
          {visibleModalConfig.content} {/* Render the dynamic content */}
        </Modal>
      )}
    </>
  );
};

export default PriceCheckModals;