import React, { useState } from "react";
import { Drawer, Button } from "antd";
import styled from "@emotion/styled";
import CreateWorkList from "./addWorkTaskList";
import { useQueryClient } from "@tanstack/react-query";
import useWorkTaskStore from "stores/workTaskStore";

const DrawerStyled = styled(Drawer)`
  .ant-drawer-title {
    font-size: 18px;
  }
  .ant-drawer-body {
    padding: 0 24px;
  }
`;

interface WorkTaskDrawerProps {
  isOpen: boolean;
  title: string;
  renderContent: () => React.ReactNode; // New prop for rendering content
  onClose?: () => void; // New prop for onClose callback
}

const WorkTaskDrawer: React.FC<WorkTaskDrawerProps> = ({ isOpen, title, renderContent, onClose }) => {
  const onCloseEvent = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <DrawerStyled
        title={title}
        width={480}
        onClose={onCloseEvent}
        open={isOpen}
        extra={null}
        footer={null}
      >
        {isOpen && renderContent()} {/* Use the renderContent callback */}
      </DrawerStyled>
    </>
  );
};

export default WorkTaskDrawer;
