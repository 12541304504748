import { Flex } from "antd";
import PriceCheckUpdateItemsCounter from "./priceCheckUpdateItemsCounter";
import { PriceCheckSelectedUpdateItemsActions } from "../toolBar/filters/actions/priceCheckSelectedUpdateItemsActions";
import WorkTaskSelect from "components/common/select/workTaskSelect";
import usePriceCheckStore from "stores/priceCheckStore";
import { convertFiltersToString } from "utility/helper";
import { FilterItem, FilterOperatorEnum } from "interfaces/baseTypes";

const FilterSectionItem = ({
  children,
  title = "",
}: {
  children: React.ReactNode;
  title?: string;
}) => {
  return (
    <Flex vertical>
      <span style={{ display: "block", marginBottom: 4, fontSize: "11px" }}>
        {title}
      </span>
      <Flex vertical justify="center" style={{ flexGrow: "1" }}>
        {children}
      </Flex>
    </Flex>
  );
};

const FilterSeprator = () => {
  return (
    <div style={{ height: "100%", width: "1px", background: "#e4e3e2" }}></div>
  );
};

const PriceCheckFilterSection = () => {
  const { setCurrentQuery } = usePriceCheckStore();

  const handleWorkTaskQueryChange = (value?: number) => {
    if (value) {
      const filters: FilterItem[] = [{ fieldName: "workTaskItems.workTaskId", value: value.toString(), operator: FilterOperatorEnum.EQUALS }]
      setCurrentQuery(filters);
    } else {
      setCurrentQuery([]);
    }
  };

  return (
    <Flex gap={15} justify="center" style={{ position: "relative" }}>
      <FilterSectionItem title="Välj arbetslista">
        <WorkTaskSelect onSelectedIdChange={handleWorkTaskQueryChange} workTaskType={1} />
      </FilterSectionItem>
      <FilterSeprator />
      <FilterSectionItem title="Åtgärd markerade">
        <PriceCheckSelectedUpdateItemsActions />
      </FilterSectionItem>
      <FilterSectionItem title="Antal">
        <PriceCheckUpdateItemsCounter />
      </FilterSectionItem>
      <FilterSeprator />
    </Flex>
  );
};

export default PriceCheckFilterSection;
