// Entity layout sizes(px)
export const CARD_ENTITY_MAX_HEIGHT = 200;
export const MAIN_ENTITY_CONTENT_WIDTH = 500;
export const MAIN_ENTITY_TOP_AREA_HEIGHT = 28;
export const PRICE_WIDGET_WIDTH = 200;
export const PRICE_WIDGET_MAX_HEIGHT = CARD_ENTITY_MAX_HEIGHT;
export const MAIN_PRICE_WIDGET_WIDTH = PRICE_WIDGET_WIDTH + 20;
export const MAX_HEIGHT_PRODUCT_IMAGE =
CARD_ENTITY_MAX_HEIGHT - MAIN_ENTITY_TOP_AREA_HEIGHT;
export const MAX_HEIGHT_PRODUCT_INFORMATION =
CARD_ENTITY_MAX_HEIGHT - MAIN_ENTITY_TOP_AREA_HEIGHT;
export const DEFAULT_GAP_SPACE = 15;
export const DEFAULT_BORDER_RADIUS = 10;

export const DEFAULT_BOX_SHADOW = "0 1px 5px 0 rgba(0, 0, 0, 0.1)"

// Theme colors
export const PRICE_CHECK_THEME_COLORS = {
  PRIMARY: "#195178",
  DEFAULT_CARD_BG: "#FFFFFF",
  DEFAULT_TOOLTIP: "#848484",
  DISCREET_TEXT: "#777777",
  DISCREET_BORDER: "#ebebeb;",
  PRIMARY_TEXT: "#1f1f1f",
  DISABLED: "#848484",
  LINK: "#195178",
  
  STATE: {
    SELECTED: "#62bbc1",
    DEFAULT: "#5a83c2",
    SUCCESS: "#62bbc1",
    WARNING: "#faad14",
    FAIL: "#ed6a5a",
  },

  TOOLTIP: {
  }
};