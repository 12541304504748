import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import { EnumInfo } from "interfaces/apiTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEnumValuesFromMetadata, translateEnumValues } from "utility/helper";
import MagicSelect from "./magicSelect";

interface TypeSelectProps {
  currentType?: number;
  onTypeChange?: (value: number | undefined) => void;
}

const TypeSelect = ({ currentType, onTypeChange }: TypeSelectProps) => {
  const { getMetadata } = useWorkTaskOpenApi();
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<number | undefined>(
    currentType
  );

  useEffect(() => {
    setSelectedType(currentType);
  }, [currentType]);

  const handleSelect = (value: number | undefined) => {
    setSelectedType(value);
    onTypeChange?.(value);
  };

  const getStatusOptions = async () => {
    const metadata = await getMetadata();
    const enhancedStatusOptions = metadata
      ? translateEnumValues(
          getEnumValuesFromMetadata(
            metadata,
            "Toolbox.Response.WorkTaskDto.WorkTaskDto",
            "Type"
          ),
          t
        )
      : [];
    return enhancedStatusOptions;
  };

  return (
    <>
      <MagicSelect<EnumInfo, number | undefined>
        value={selectedType}
        formatOption={(option) => {
          return {
            value: option?.intValue,
            label: option?.enumDisplayValue
              ? option.enumDisplayValue
              : "Unknown",
          };
        }}
        onSelect={handleSelect}
        placeholder="Välj en produkttyp"
        fetchOptions={getStatusOptions}
      />
    </>
  );
};

export default TypeSelect;
