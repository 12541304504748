import React, { useState } from "react";
import { Settings, History, LogIn } from "lucide-react";
import type { MenuProps } from "antd";
import { Avatar, Button, Dropdown, message, Popover, Space } from "antd";
import styled from "@emotion/styled";
import { useGetIdentity, useLink, useLogout } from "@refinedev/core";
import { IUser } from "interfaces";
import { Text } from "components/common/text";
import initials from "initials";

// const UserLabel = styled('span')`
//     font-weight: 600;
//     color: #000;
//     font-size: 14px;
// `

// const UserAvatar = styled(Avatar)`
//     margin-bottom: 2px;
// `

export const UserMenu: React.FC = () => {
  const { mutate: logout } = useLogout();
  const { data: identity } = useGetIdentity<IUser>();
  const Link = useLink();

  //   const onClick: MenuProps["onClick"] = ({ key }) => {
  //     switch (key) {
  //         case "1":
  //             navigate("/settings");
  //             break;
  //         case "2":
  //             logout()
  //             break;
  //         default:
  //             break;
  //     }
  //   };

  //   const items: MenuProps["items"] = [
  //     {
  //       label: "Inställningar",
  //       key: "1",
  //     },
  //     {
  //       label: "Logga ut",
  //       key: "2",
  //     },
  //   ];

  const content = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        strong
        style={{
          padding: "12px 20px",
        }}
      >
        {identity?.email}
      </Text>
      <div
        style={{
          borderTop: "1px solid #d9d9d9",
          padding: "4px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Link to={"/settings"}>
          <Button
            style={{ textAlign: "left", display: "flex", alignItems: "center" }}
            icon={<Settings size={"1rem"} className="anticon" />}
            type="text"
            block
          >
            <span style={{ marginTop: "2px" }}>Inställningar</span>
          </Button>
        </Link>
        <Link to={"/changelog"}>
          <Button
            style={{ textAlign: "left", display: "flex", alignItems: "center" }}
            icon={<History size={"1rem"} className="anticon" />}
            type="text"
            block
          >
            <span style={{ marginTop: "2px" }}>Changelog</span>
          </Button>
        </Link>
        <Button
          style={{ textAlign: "left", display: "flex", alignItems: "center" }}
          icon={<LogIn size={"1rem"} className="anticon" />}
          type="text"
          danger
          block
          onClick={() => logout()}
        >
          Logga ut
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      placement="bottomRight"
      content={content}
      trigger="click"
      overlayStyle={{ width: 200 }}
    >
      <Button shape="circle" type="primary">
        {initials(identity?.email?.toUpperCase() ?? "")}
      </Button>
    </Popover>
  );
};
