import { WorkTask } from "interfaces/apiTypes";
import { BaseItem } from "interfaces/baseTypes";
import { create } from "zustand";
import { FilterItem } from "interfaces/baseTypes";

type WorkTaskStore = {
  // Create task modal
  createTaskIsOpen: boolean;
  setCreateTaskIsOpen: (isOpen: boolean) => void;

  // Update task modal
  updateTaskIsOpen: boolean;
  setUpdateTaskIsOpen: (isOpen: boolean) => void;

  // Loading
  globalLoading: { isLoading: boolean; message: string };
  setGlobalLoading: (isLoading: boolean, message?: string) => void;

  // Reset
  resetState: () => void;

  // Selected work task
  selectedWorkTask: BaseItem<WorkTask> | null; // Changed from selectedTaskList
  setSelectedWorkTask: (data: BaseItem<WorkTask> | null) => void; // Changed from setSelectedTaskList

  // Checked work tasks
  checkedWorkTasks: BaseItem<WorkTask>[];
  setCheckedWorkTasks: (tasks: BaseItem<WorkTask>[]) => void; // Changed from setCheckedTaskLists

  // Add filter items to the store
  currentFilterItems: FilterItem[]; // Changed from filterItems
  setCurrentFilterItems: (items: FilterItem[]) => void; // Changed from setFilterItems
};

// Initial state definition
const initialState = {
  createTaskIsOpen: false,
  updateTaskIsOpen: false,
  globalLoading: {
    isLoading: false,
    message: "",
  },
  selectedWorkTask: null, // Changed from selectedTaskList
  checkedWorkTasks: [], // Changed from checkedTaskLists
  currentFilterItems: [], // Changed from filterItems
};

const useWorkTaskStore = create<WorkTaskStore>((set) => ({
  ...initialState,

  // Create task modal
  setCreateTaskIsOpen: (isOpen) => set({ createTaskIsOpen: isOpen }),
  setUpdateTaskIsOpen: (isOpen) => set({ updateTaskIsOpen: isOpen }),
  // Loading
  setGlobalLoading: (isLoading, message = "") => {
    set({
      globalLoading: {
        isLoading,
        message,
      },
    });
  },

  // Reset
  resetState: () => set(initialState),

  // Selected work task
  setSelectedWorkTask: (data) => set({ selectedWorkTask: data }), // Changed from setSelectedTaskList

  // Checked work tasks
  setCheckedWorkTasks: (tasks) => set({ checkedWorkTasks: tasks }), // Changed from setCheckedTaskLists

  // Add filter items setter
  setCurrentFilterItems: (items) => set({ currentFilterItems: items }), // Changed from setFilterItems
}));

export default useWorkTaskStore;
