import { Button } from "antd";
import React, { useEffect, useState } from "react";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";
import useWorkTaskStore from "stores/workTaskStore";
import { FilterBuilder } from "components/filters/filterBuilder";
import WorkTaskSectionHeader from "./header/sectionHeader";
import { Filter } from "lucide-react";
import { defaultSectionHeaderIconStyle } from "../styles/workTaskStyles";
import useProductOpenApi from "apiClients/useProductOpenApiClientV2";
import { convertFiltersToString, convertStringToFilters } from "utility/helper";
import { WorkTaskUpdateDto } from "interfaces/apiTypes";
import { notification } from 'antd';
import { useQueryClient } from "@tanstack/react-query";

const WorkTaskFilterBuilder: React.FC<{ sectionHeaderTitle?: string }> = ({
  sectionHeaderTitle,
}) => {
  const { getMetadata } = useProductOpenApi();
  const { updateWorkTask } = useWorkTaskOpenApi();
  const { selectedWorkTask, currentFilterItems, setCurrentFilterItems } = useWorkTaskStore();
  const queryClient = useQueryClient();
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setCurrentFilterItems(convertStringToFilters(selectedWorkTask?.item.queryString ?? ""));
    setIsModified(false);
  }, [selectedWorkTask?.id]);

  useEffect(() => {
    setIsModified(currentFilterItems.length > 0 && 
      JSON.stringify(currentFilterItems) !== JSON.stringify(convertStringToFilters(selectedWorkTask?.item.queryString ?? "")));
  }, [currentFilterItems]);

  const saveNewFilter = async() => {
    if (!selectedWorkTask?.id) {
      return;
    }
    const newWorkTask: WorkTaskUpdateDto = {
      name: selectedWorkTask.item.name,
      description: selectedWorkTask.item.description,
      queryString: convertFiltersToString(currentFilterItems),
      status: selectedWorkTask.item.status,
      archived: selectedWorkTask.item.archived,
      assignedToId: selectedWorkTask.item.assignedToId,
      dueDate: selectedWorkTask.item.dueDate,
      completedDate: selectedWorkTask.item.completedDate,
    }
    const updatedWorkTask = await updateWorkTask(selectedWorkTask.id, newWorkTask);
    if (updatedWorkTask) {
      notification.success({
        message: 'Filter sparad',
        description: 'Sökningen har sparats.',
        placement: 'topRight',
      });
      setIsModified(false);
    } else {
      notification.error({
        message: 'Fel vid sparande',
        description: 'Det gick inte att spara sökningen.',
        placement: 'topRight',
      });
    }
    queryClient.invalidateQueries({ queryKey: ['workTasks'] });
  }

  return (
    <div>
      <div style={{ position: 'sticky', top: 0, zIndex: 11 }}>
        <WorkTaskSectionHeader
          title={sectionHeaderTitle ?? ""}
          subText={selectedWorkTask?.id ? `${selectedWorkTask?.item?.name ?? ""} (${selectedWorkTask?.id})` : "Ingen lista vald"}
          icon={<Filter style={defaultSectionHeaderIconStyle} />}
          showSeparatorLine={true}
          renderActionElement={() => (
            <Button
              type="primary"
              disabled={!selectedWorkTask?.id || currentFilterItems.length === 0 || !isModified}
              onClick={async () => {
                await saveNewFilter();
              }}
            >
              Spara
            </Button>
          )}
        />
      </div>
      {selectedWorkTask !== null && selectedWorkTask?.id ? (
        <FilterBuilder
          key={selectedWorkTask.id}
          metdataFunction={getMetadata}
          initialFilterItems={currentFilterItems}
          onFiltersChange={(value) => setCurrentFilterItems(value)}
        ></FilterBuilder>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            height: "100%",
          }}
        >
          Ingen lista vald
        </div>
      )}
    </div>
  );
};

export default WorkTaskFilterBuilder;

